export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET = "RESET";
export const DRAWER = "DRAWER";

export const MAXDATE="MAXDATE";
export const TODAY="TODAY";
export const SETSCHEME="SETSCHEME";
export const SETCAT="SETCAT";
export const SETSUBCAT="SETSUBCAT";
export const SHOWMORE="SHOWMORE"
export const SETSEASON="SETSEASON"
export const SETSTATE="SETSTATE"
export const SETDISTRICT="SETDISTRICT"
export const SETBLOCK="SETBLOCK"
export const SETGP="SETGP"
export const SETVILLAGE="SETVILLAGE"
export const NAYAPANCHAYAT="NAYAPANCHAYAT"
export const SETALLCROPS="SETALLCROPS"
export const SETCROP="SETCROP"
export const SETPIN="SETPIN"
export const SETIDSR="SETIDSR"
// Claim Intimation Form 
export const SETACTIVESTEP="SETACTIVESTEP"
export const SETSECTION1="SETSECTION1"
export const SETSECTION2="SETSECTION2"
export const SETSRCLEAR="SETSRCLEAR"
// others Location
export const SETTBO="SETTBO"
export const SETNPO="SETNPO"
export const SETGPO="SETGPO"
export const SETVO="SETVO"
export const SETCNO="SETCNO"





