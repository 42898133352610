import React, { useEffect, useState } from "react";
import TopNav from "../Components/Navigation/TopNav";
import MyDrawer from "../Components/Navigation/MyDrawer";
import {
  makeStyles,
  Typography,
  Grid,
  Card,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { FcSynchronize } from "react-icons/fc";
import DashData from "./DashComp/DashData";
import LineChart from "./DashComp/LineChart";
import TreeMapChart from "./DashComp/TreeMapChart";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  dashbody: {
    height: "100vh",
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  cardArea: {
    background:
      "linear-gradient(to left bottom, #705fff, #6870ff, #667ffd, #6a8df8, #7399f2, #69a7f6, #65b3f8, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)",
    minHeight: 400,
    padding: theme.spacing(2),
  },
  dataCard: {
    maxWidth: 280,
    borderRadius: 15,
    justifyContent: "center",
    boxShadow:
      "-4px -4px 20px rgba(255, 255, 255, 1), 4px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 75,
    fontSize: theme.spacing(6),
  },
  textBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  chartArea: {
    background:
      "linear-gradient(to left top, #705fff, #6870ff, #667ffd, #6a8df8, #7399f2, #69a7f6, #65b3f8, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)",
    minHeight: 500,
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(2),
  },
  graph: {
    padding: theme.spacing(),
  },
  greeting: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [treeMapData, setTreeMapData] = useState([]);
  const [dataCard, setDataCard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState({
    label: "Only Today Data",
    value: "getTodayDate",
    isTrue: false,
  });
  const [name, setName] = useState('User');

  useEffect(() => {
    // Retrieve data from local storage
    const storedData = localStorage.getItem('data');

    // Check if data exists in local storage
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const name = parsedData.name
      setName(name);
    }
  }, []);

  // Get the current time and determine the greeting
  const now = new Date();
  let greeting = "Good Morning 🌞";
  if (now.getHours() >= 12 && now.getHours() < 17) {
    greeting = "Good Afternoon 🌤️";
  } else if (now.getHours() >= 17) {
    greeting = "Good Evening 🌙";
  } else if (now.getHours() <= 3) {
    greeting = "Working late Night 🌃";
  }
  

  function changeType() {
    if (type.value === "getTodayDate") {
      setType({ label: "This Month Data", value: "getThisMonth", isTrue: true });
    } else if (type.value === "getThisMonth") {
      setType({ label: "Only Today Data", value: "getTodayDate", isTrue: false });
    }
  }

  const getChartData = () => {
    let data = { type: type.value };
    setIsLoading(true);
    axios
      .post("/api/dashboard/getDashData/getAll", data)
      .then((res) => {
        setTreeMapData(res.data.treeMapData);
        setDataCard(res.data.dataCard);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getChartData();
  }, [type]);

  return (
    <div className={classes.dashbody}>
      <TopNav />
      <MyDrawer />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.cardArea}>
          
          <Grid container spacing={4}>
            
            <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h6" className={classes.greeting}>
            {`${greeting}`}
          </Typography></Grid>
          <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

              <Tooltip title="Refresh" arrow>
                <IconButton aria-label="refresh" style={{ marginRight: 15 }} onClick={getChartData}>
                  <FcSynchronize style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
              <FormControlLabel
                control={<Switch color="secondary" checked={type.isTrue} onChange={changeType} />}
                label={type.label}
              />
            </Grid>
            {isLoading ? (
              <DashData dataCard={myDataCard} />
            ) : (
              <DashData dataCard={dataCard} />
            )}
          </Grid>
        </div>
        <div className={classes.chartArea}>
          <Grid container spacing={4}>
            {type.value === "getThisMonth" && (
              <Grid item xs={12} md={12}>
                <Card className={classes.graph}>
                  <LineChart />
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Card className={classes.graph}>
                {isLoading ? (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                ) : (
                  <TreeMapChart treeMapData={treeMapData} />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}

const myDataCard= [
  {
      "title": "Claim Intimation",
      "value": "Loading.."
  },
  {
      "title": "Non Claim Related",
      "value": "Loading.."
  },
  {
      "title": "Claim Related",
      "value": "Loading.."
  },
  {
      "title": "Claim Related- Claim Payment not received",
      "value": "Loading.."
  },
  {
      "title": "Claim Related - Survey status related",
      "value": "Loading.."
  },
  {
      "title": "Claim Related - Less claim amount received",
      "value": "Loading.."
  },
  {
      "title": "Claim Related - Claim Inquiry but no intimation",
      "value": "Loading.."
  },
  {
      "title": "Claim Related - Reference Number Required",
      "value": "Loading.."
  },
  {
      "title": "Claim Related- Payment re-issuance in different account",
      "value": "Loading.."
  },
  {
      "title": "Non Crop Insurance",
      "value": "Loading.."
  },
  {
      "title": "Claim Related - How to Lodge Claim",
      "value": "Loading.."
  },
  {
      "title": "State/District/Crop Not Found",
      "value": "Loading.."
  },
  {
      "title": "Non Claim related",
      "value": "Loading.."
  },
  {
      "title": "Enrolment related – Scheme inquiry",
      "value": "Loading.."
    },
    {
        "title": "Enrolment related - Portal technical error",
        "value": "Loading.."
    },
    {
        "title": "Enrolment related - Portal application correction / Updation",
        "value": "Loading.."
    },
    {
        "title": "Enrolment related - Application Status Inquiry",
        "value": "Loading.."
    },
    {
        "title": "Enrolment related - Policy no. required",
        "value": "Loading.."
    },
    {
        "title": "Inland Letter inquiry",
        "value": "Loading.."
    },
    {
        "title": "Inland Letter- Changes Required",
        "value": "Loading.."
    },
    {
        "title": "Insufficient Info",
        "value": "Loading.."
    },
    {
        "title": "Language Barrier",
        "value": "Loading.."
    },
    {
        "title": "Call Dropped",
        "value": "Loading.."
    },
    {
        "title": "Old Intimation",
        "value": "Loading.."
    }
]
