import React, { useEffect, useState,useContext, useRef} from "react";
// import { Redirect } from "react-router-dom";
import { MdRemoveRedEye, MdVisibilityOff } from "react-icons/md";
import {
	Grid,
	makeStyles,
	Collapse,InputAdornment,
	Typography,
	Card, TextField,Button
} from "@material-ui/core";
import { MainContext } from "../Context/MainContext";
import { LOGIN_USER } from "../Context/types";
import MySnackbar from "../MySnackbar";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
	loginBody: {
		background: "linear-gradient(to right, #86fde8, #acb6e5)",
		height: "100vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	card: {
		padding: theme.spacing(2),
		maxWidth:400,
		borderRadius:25
	}

}));


export default function Login(props) {
	const classes = useStyles();
	const [loginId, setLoginId] = useState("");
	const [loginPass, setLoginPass] = useState("");
	const [passwordMode, setPassMode] = useState(true);
	const [showPass, setShowPass] = useState(true);
	const [openBox, setOpenBox] = useState(true);
	const snackRef = useRef();
	const { state, dispatch } = useContext(MainContext);

	const sendOTP=()=>{
		axios
		.post("/api/auth/user/sendotp", {mobileNo:loginId })
		.then((res) => {
			if(res.data.variant ==="success"){
				setOpenBox(true)
			}
			snackRef.current.handleSnack(res.data)
		})
		.catch((err) => console.log(err));
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		if(passwordMode){
			// Login With Password
		await axios
			.post("api/auth/user/loginWithPassword", {loginId, password:loginPass })
			.then((res) => {
				if (res.data.success) {
					dispatch({ type: LOGIN_USER, payload: res.data });
				} else {
					snackRef.current.handleSnack(res.data);
				}
			})
			.catch((err) => console.log(err));

		}else if (passwordMode=== false){
		
			if(loginId.length === 10 &&  loginPass === ""){
				sendOTP()
			
			}else if (loginId.length === 10 &&  loginPass.length === 4){
				axios
			.post("/api/auth/user/loginWithOtp", {mobileNo:loginId, otp:loginPass })
			.then((res) => {
				if (res.data.success) {
					dispatch({ type: LOGIN_USER, payload: res.data });
					snackRef.current.handleSnack(res.data);
				} else {
					snackRef.current.handleSnack(res.data);
				}
			})
			.catch((err) => console.log(err));
				
			}

			
		}
	
		// const user = { emu: loginId, password };
		// await axios
		// 	.post("/api/auth/login", user)
		// 	.then((res) => {
		// 		if (res.data.success) {
		// 			dispatch({ type: LOGIN_USER, payload: res.data });
		// 		} else {
		// 			snackRef.current.handleSnack(res.data);
		// 		}
		// 	})
		// 	.catch((err) => console.log(err));
	};
	
	
	useEffect(() => {
		let isSubscribed= true
		if(isSubscribed){
				if (state.isAuthenticated) {
		switch (state.designation) {
			case "Admin":
				 props.history.push("/dashboard");
				 break;
			case "User":
				 props.history.push("/dashboard");
				 break;
			case "Supervisor":
				 props.history.push("/lead");
				 break;
			case "Recording":
				 props.history.push("/RecordingData");
				 break;
			default:
				 props.history.push("/login");
				 break;
		}
		}
		return () => {
			isSubscribed = false;
		};
	}
	}, [props.history,state.designation,state.isAuthenticated])
	return (
		<div className={classes.loginBody}>
			<Card className={classes.card} >
				<Typography variant="h5" style={{ marginBottom: 20 }} align="center" gutterBottom component="h2">
					Login
       			</Typography>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								type={passwordMode ?  "text" : "number"}
								fullWidth
								autoFocus
								disabled={openBox && passwordMode === false }
								label={passwordMode ? "Enter Mobile No. / Email Id" : "Enter Your Mobile Number" }
								placeholder={ passwordMode ? "Mobile No. or Email Id" : "Mobile No."}
								value={loginId}
								onChange={(e) => setLoginId(e.target.value)}
							/>

						</Grid>
						
					
						<Grid item xs={12}>
						<Collapse in={openBox}>
							<TextField
								variant="outlined"
								required
								type={passwordMode ? showPass ? "password" : "text" : "number"}
								fullWidth
								InputProps={passwordMode ? {
									endAdornment: (
										<InputAdornment position="end" onClick={() => setShowPass(!showPass)}>
											{showPass ? <MdRemoveRedEye /> : <MdVisibilityOff />}
										</InputAdornment>
									),
								} : null}
								label={passwordMode ?  "Enter Password" : "Enter OTP"}
								value={loginPass}
								onChange={(e) => setLoginPass(e.target.value)}
							/>
</Collapse>
						</Grid>

							
						<Grid item xs={12}>
								<Button onClick={handleSubmit}  variant="contained" color="primary" fullWidth >
									{openBox ? "Login Now" : "Get OTP" }
								</Button>
							</Grid>
							<Grid item xs={12} style={{display:"flex",justifyContent:"flex-end"}}>
							<Typography variant="caption" style={{cursor:"grab"}} onClick={()=> {setPassMode(!passwordMode); setOpenBox(!openBox); setShowPass(true)}} >
					{passwordMode ? 'Go with OTP':'Go with Password'}
        </Typography>
							</Grid>
					</Grid>

			</Card>
			<MySnackbar ref={snackRef} />
		</div>
	);
}


