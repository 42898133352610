import React, { createContext, useReducer, useEffect } from "react";
import {MAXDATE,TODAY,SETSCHEME,SETCAT,SETSUBCAT,SHOWMORE,SETSEASON,SETSTATE,SETDISTRICT,SETBLOCK,SETGP,SETVILLAGE,NAYAPANCHAYAT,SETCROP,SETALLCROPS,SETPIN,SETACTIVESTEP, SETSECTION1,SETSECTION2,SETSRCLEAR,SETIDSR,SETTBO,SETNPO,SETGPO,SETVO,SETCNO} from "./types";
export const SRContext = createContext();

const preState = {
	id: "",
    page:"",
    maxDate:"2022-04-01",
    today:"2022-04-01",
    category:"",
    subCat:"",
    showMore:false,
    schemeName:null,
    seasonName:null,
    state:null,
    districtName:null,
    tahsilBlock:null,
    // tahsilBlockOther:null,
    gramPanchayat:null,
    village:null,
    nayaPanchayat:"",
    cropName:null,
    allCrops:[],
    pin:"",

    // Claim Intimation
    activeStep:0,
    callerName:"",
    callerType:"",
    callerTypeOther:"",
    farmerName:"",
    fatherName:"",
    loanee:false,
    callerNumber:"",
    connectionId:"",
    farmerContact:"",
    aadhar:"",
    kcc:"",
    application:"",
    bankName:null,
    bankNameOther:"",
    accountNo:"",

    rows:[{lossDate:"", sowingDate:"",harvestDate:"",cause:"",showCause:false,causeOther:"",lossDesc:"",cropStage:"", khata:"",affectedCrop:null,lossPercent:1}],
    remark:"",
};

export const SRProvider = (props) => {
	const [SRState, SRDispatch] = useReducer(SRReducer, preState);
	useEffect(() => {
		return () => {
			SRDispatch({ type: "HANDLECLEAR" });
		};
	}, []);
    
	return <SRContext.Provider value={{ SRState, SRDispatch }}>{props.children}</SRContext.Provider>;
};

const SRReducer = (state, action) => {
	switch (action.type) {
		case MAXDATE: {
			state.maxDate = action.payload;
			state.today = action.payload;
			return {
				...state,
			};
		}
        case SETIDSR:{
            return{
                ...state,
                ...action.payload
            }
        }
        case TODAY: {
			state.today = action.payload;
			return {
				...state,
			};
		}
        case SETSCHEME:{
            state.schemeName = action.payload;
            state.seasonName = null;
            state.state = null;
            state.districtName = null;
            state.tahsilBlock = null;
            state.gramPanchayat = null;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
			return {
				...state,
			};
        }
        case SETCAT:{
            state.category = action.payload;
			return {
				...state,
			};
        }
        case SETSUBCAT:{
            state.subCat = action.payload;
			return {
				...state,
			};
        }
        case SHOWMORE:{
            state.showMore = action.payload;
			return {
				...state,
			};
        }
        case SETSEASON:{
            state.seasonName = action.payload;
            state.state = null;
            state.districtName = null;
            state.tahsilBlock = null;
            state.gramPanchayat = null;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        }
        case SETSTATE:{
            state.state = action.payload;
            state.districtName = null;
            state.tahsilBlock = null;
            state.gramPanchayat = null;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        }
        case SETDISTRICT:{
            state.districtName = action.payload;
            state.tahsilBlock = null;
            state.gramPanchayat = null;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        }
		case SETBLOCK:{
            state.tahsilBlock = action.payload;
            state.gramPanchayat = null;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        }
        case SETGP:{
            state.gramPanchayat = action.payload;
            state.village = null;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        } 
        case SETVILLAGE:{
            state.village = action.payload;
            state.nayaPanchayat = "";
            state.cropName = null;
            state.pin = "";
            state.allCrops=[]
			return {
				...state,
			};
        }
        case SETALLCROPS:{
            state.allCrops=action.payload;
            return {
				...state,
			};
        }
        case NAYAPANCHAYAT:{
            state.nayaPanchayat = action.payload;
			return {
				...state,
			};
        }
        case SETCROP:{
            state.cropName = action.payload;
			return {
				...state,
			};
        }
        case SETPIN:{
            state.pin = action.payload;
			return {
				...state,
			};
        }
        // setting others by vivek
        case SETTBO:{
            state.tahsilBlockOther = action.payload;
			return {
				...state,
			};
        }       
        case SETNPO:{
            state.nayaPanchayatOther = action.payload;
			return {
				...state,
			};
        }       
        case SETGPO:{
            state.gramPanchayatOther = action.payload;
			return {
				...state,
			};
        }       
        case SETVO:{
            state.villageOther = action.payload;
			return {
				...state,
			};
        }       
        case SETCNO:{
            state.cropNameOther = action.payload;
			return {
				...state,
			};
        }

        // Claim Intimation Form 
      case SETACTIVESTEP:{
            state.activeStep = action.payload;
            return {
            ...state,
             };
      }
        case SETSECTION1:{
            state.activeStep = 1;
            state.callerName = action.payload.callerName;
            state.callerType = action.payload.callerType;
            state.callerTypeOther = action.payload.callerTypeOther;
            state.farmerName = action.payload.farmerName;
            state.fatherName = action.payload.fatherName;
            state.loanee = action.payload.loanee;
            state.callerNumber = action.payload.callerNumber;
            state.connectionId = action.payload.connectionId;
            state.farmerContact = action.payload.farmerContact;
            state.aadhar = action.payload.aadhar;
            state.kcc = action.payload.kcc;
            state.application = action.payload.application;
            state.bankName = action.payload.bankName;
            state.bankNameOther=action.payload.bankNameOther;
            state.accountNo = action.payload.accountNo;
            state.remark = action.payload.remark;
			return {
				...state,
			};
        }
        case SETSECTION2:{
            state.activeStep = 2;
            state.rows=action.payload.rows;
            state.remark=action.payload.remark;
			return {
				...state,
			};
        }
		case SETSRCLEAR: {
            state.rows = [];
			return {
				...preState,
			};
		}

		default:
			return state;
	}
};
