import React,{useState} from 'react'
import {
    Menu,
    MenuItem,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    TextField,
    DialogContent,
    DialogActions,
    Button,

} from "@material-ui/core";
import axios from "axios"
import {FcMissedCall,FcWikipedia } from "react-icons/fc";



export default function SaveIncomp({load}) {
  const [iMenu, showIMenu] = useState(null);
   const incomptOption = ["Insufficient Info", "Language Barrier", "Call Dropped","Duplicate (NCIP)"];
   const [ncipBox, setNcipBox]=useState(false)
   const [isSaving,setIsSaving]= useState(false);
   const [ncip, setNcip]=useState("")
   const handleIncomp=(e)=>{
      showIMenu(null)
      let newLoad = {...load,category:e || "Duplicate Intimation (NCIP)",subCat:e || "Duplicate Intimation (NCIP)",ncip}
      setIsSaving(true) 
      axios
            .post(`/api/sr/saveSr/saveMe/`,newLoad)
            .then((res) => {
                if(res.data.variant==="success"){
                    alert(`Your Reference Id is ${res.data.responseData[0]}`)
                    window.location.reload()
                }else alert(res.data.message);}
                )
            .catch((err) => console.log(err))
            .finally(() => {
              setIsSaving(false);
            }); 
      
   }
    return (
        <div>
   {isSaving?<FcWikipedia/>: <Tooltip title="Save Incomplete" arrow>
          <IconButton color="primary" onClick={(e)=>showIMenu(e.currentTarget)} aria-label="save-incomplete"  aria-controls="save-incomplete">
            <FcMissedCall/>
          </IconButton>
          </Tooltip>}
          <Menu
      id="save-incomplete"
      anchorEl={iMenu}
      open={Boolean(iMenu)}
      onClose={()=>showIMenu(null)}
      PaperProps={{
        style: {
          width: '20ch',
        },
      }}
    >
      {incomptOption.map((option) => (
        <MenuItem key={option} onClick={()=> {
          if(option==="Duplicate (NCIP)"){
            setNcipBox(true)
             showIMenu(null)
          }else handleIncomp(option)
        }} >
          {option}
        </MenuItem>
      ))}
    </Menu>
      <Dialog
        open={ncipBox}
        onClose={()=>setNcipBox(false)}
        aria-labelledby="ncip"
        aria-describedby="alert-ncip-description"
      >
        <DialogTitle id="ncip">Enter NCIP Claim Reference Number</DialogTitle>
        <DialogContent>
            <TextField
            autoFocus
            margin="dense"
            type="number"
            inputProps={{ minLength: "16", maxLength: "16" }}
            value={ncip}
            placeholder="Enter NCIP Claim Ref. No."
            onChange={e=>setNcip(e.target.value)}
            id="name"
            label="Enter NCIP Claim Ref. No."
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setNcipBox(false)} >
            Cancel
          </Button>
          <Button  color="primary" onClick={()=>handleIncomp()} autoFocus>
            Save Incomplete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    )
}
