import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MuiThemeProvider, createMuiTheme, withStyles } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import MainRoute from "./Components/Routes/MainRoute";
import { deepPurple, blue } from "@material-ui/core/colors";
import { MainProvider } from "./Components/Context/MainContext";

const GlobalCSS = withStyles({
	"@global": {
		"*": {
			margin: 0,
			"-webkit-font-smoothing": "antialiased",
			"-moz-osx-font-smoothing": "grayscale",
			"&::-webkit-scrollbar": {
				width: "12px",
			},
			"&::-webkit-scrollbar-track": {
				"-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.3)",
				borderRadius: 10,
			},
			"&::-webkit-scrollbar-thumb": {
				"-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.5)",
				borderRadius: 10,
			},
			"& a": {
				textDecoration: "none",
				color: "rgb(0, 110, 255)",
				transition: "all 0.3s ease 0s",
			},
		},
		".center": {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},

	},
})(() => null);

const theme = createMuiTheme({
	palette: {
		primary: blue,
		secondary: deepPurple,
	},
	status: {
		danger: "orange",
	},

});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<GlobalCSS />
		<BrowserRouter>
			<MainProvider>
				<MainRoute />
			</MainProvider>
		</BrowserRouter>
	</MuiThemeProvider>,
	document.getElementById("root"),
);



