import React,{useRef, useState,lazy,Suspense} from "react";
import TopNav from "../../Components/Navigation/TopNav"
import MyDrawer from "../../Components/Navigation/MyDrawer";
import {
	makeStyles,
    Card,
    Typography,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Tooltip,
    Switch,
    Button,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { FcExpand,FcSearch,FcRefresh,FcSurvey,FcCloseUpMode,FcClock,FcWorkflow } from "react-icons/fc";
import MySnackbar from "../../Components/MySnackbar";
import axios from "axios"
const ViewHistory = lazy(() => import("./ViewHistory"));
const AddRemarks = lazy(() => import("./AddRemarks"));


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    workArea:{
        padding:theme.spacing(2)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
    
}));


export default function Search() {
	const classes = useStyles();
	const snackRef = useRef();
    const [secondryReqNumber, setServiceNo]=useState("");
    const [callerNumber,setCallerNo] =useState("");
    const [expandBasic,setExpand]=useState(true);
    const [showAdv,setAdv]=useState(false);
    const [application, setApplication]=useState("")
    const [accountNo,setAccount]=useState("");
    const [kcc, setKcc]=useState("")
    const [farmerContact, setFarmerContact] = useState("")
    const [rows,setRows]= useState([])
    const [showHistory,setShowHistory]=useState("")
    const [showRemarks,setShowRemarks]=useState("")

    const handleChange=(e,p,v)=>{
       if(v==="number"){
           const re = /^[0-9\b]+$/;
           if (e === '' || re.test(e)) {
             switch (p) {
                case "callerNumber":
                setCallerNo(e)
                break;
                case "application":
                setApplication(e);
                break;
                case "accountNo":
                setAccount(e)
                break;
                case "kcc":
                setKcc(e)
                 break;
                case "farmerContact":
                setFarmerContact(e)
                break;
                default:
                   break;
             }
           }
        }else if(v==="text"){
            var re = /^[A-Za-z_ ]*$/;
            if (e === '' || re.test(e)) {
               switch (p) {
                  case "secondryReqNumber":
                     break;
                  default:
                     break;
               }
            }
   
         }
    }
    const handleClear=()=>{
        setServiceNo("");
        setCallerNo("");
        setApplication("");
        setAccount("");
        setKcc("");
        setFarmerContact("")
    }
    const handleSearch=()=>{
        axios
            .post(`/api/sr/getSr/allsr/searchSr`,{secondryReqNumber,callerNumber,application,accountNo,kcc,farmerContact})
            .then((res) => {
              if(res.data.length > 0){
                   setRows(res.data)
                    snackRef.current.handleSnack({message:"Search Result Found.", variant:"success"})
                }else snackRef.current.handleSnack({message:"No Result Found.", variant:"info"})
              }
                )
            .catch((err) => console.log(err)); 
    }
    const handleExactFind=()=>{
        axios
            .post(`/api/sr/getSr/allsr/findExactSr`,{secondryReqNumber,callerNumber,application,accountNo,kcc,farmerContact})
            .then((res) => {
              if(res.data.length > 0){
                   setRows(res.data)
                    snackRef.current.handleSnack({message:"Find Result Found.", variant:"success"})
                }else snackRef.current.handleSnack({message:"No Result Found.", variant:"info"})
              }
                )
            .catch((err) => console.log(err)); 
    }
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
        <Card className={classes.workArea}>
                <Accordion expanded={expandBasic}>
        <AccordionSummary
        onClick={()=>setExpand(!expandBasic)}
          expandIcon={<FcExpand />}
          aria-controls="basic-search"
          id="basic-search"
        >
          <Typography className={classes.heading}>Basic Search</Typography>
          <span style={{flexGrow:0.5}}/>
          <FormControlLabel
        control={<Switch color="secondary" checked={showAdv} onChange={() => {setAdv(!showAdv);setExpand(true)}} />}
        label="Advance Search" labelPlacement="start"
      />
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
            <TextField fullWidth value={secondryReqNumber} onChange={e=>setServiceNo(e.target.value)} inputProps={{ maxLength: "23" }} label="Service Request Number" placeholder="Referance Number"  />
            </Grid>
            <Grid item xs={12} md={4}>
            <TextField fullWidth value={callerNumber} onChange={e=>handleChange(e.target.value,"callerNumber","number")} inputProps={{ maxLength: "10" }} label="Callers Number"  placeholder="10 Digit Caller No." />
            </Grid>
            {showAdv && <>
            <Grid item xs={12} md={4}>
           <TextField fullWidth required label="Application No."  value={application} onChange={e=>handleChange(e.target.value,"application","number")} inputProps={{ maxLength: "21" }} placeholder="Enter Application No." />
            </Grid>
            <Grid item xs={12} md={4}>
           <TextField fullWidth value={accountNo}  onChange={e=>handleChange(e.target.value,"accountNo","number")} label="Bank Account No." inputProps={{ maxLength: "21" }} placeholder="Bank A/c No." />
            </Grid>
            <Grid item xs={12} md={4}>
           <TextField fullWidth label="Kisan Credit Card No." value={kcc} onChange={e=>handleChange(e.target.value,"kcc","number")}  inputProps={{ maxLength: "21" }} placeholder="KCC Number" />
            </Grid>
            <Grid item xs={12} md={4}>
            <TextField fullWidth required label="Farmer Contact nos" value={farmerContact} onChange={e=>handleChange(e.target.value,"farmerContact","number")} inputProps={{ maxLength: "10" }} placeholder="Farmer Contact nos" />
            </Grid>
            
            </>}
            <Grid item xs={12} className="center">
           
            <Button
        variant="outlined"
        color="primary"
        startIcon={<FcSearch />}
        onClick={handleSearch}
      >
        Search
      </Button>
      <span style={{flexGrow:0.05}}/>
      <Button
        endIcon={<FcRefresh />}
        onClick={handleClear}
      >
        Reset
      </Button>
      <span style={{flexGrow:0.05}}/>
      <Button 
        startIcon={<FcWorkflow />}
        onClick={handleExactFind}
        color="secondary"
        >
          Exact Find - beta
      </Button>
            </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
        </Card>
        <div className={classes.workArea}>
        <TableContainer>
                <br/>
                <Typography variant="subtitle2" color="textSecondary" align="center"> Search Report</Typography>
      <Table size="small" aria-label="Search-table">
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell size="small" align="left">Caller Number</TableCell>
            <TableCell size="small" align="left">Farmer Name</TableCell>
            <TableCell size="small" align="left">SR&nbsp;No</TableCell>
            <TableCell size="small" align="center">Bank&nbsp;AC&nbsp;/&nbsp;KCC/App No.</TableCell>
            <TableCell size="small" align="center">Date&nbsp;Time</TableCell>
            <TableCell size="small" align="center">Category</TableCell>
            <TableCell size="small" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell size="small" align="left"> {row.callerNumber}</TableCell>
              <TableCell size="small" align="left">{row.farmerName}</TableCell>
              <TableCell size="small" align="left">{row.secondryReqNumber}</TableCell>
              <Tooltip title={row.accType} arrow><TableCell size="small" align="center">{row.accNumber}</TableCell></Tooltip>
              <Tooltip title={`Time : ${row.time}`} arrow><TableCell size="small" align="center">{row.date}</TableCell></Tooltip>
              <Tooltip title={row.subCat} arrow><TableCell size="small" align="center">{row.category}</TableCell></Tooltip>
              <TableCell size="small" className={classes.iconBtn} align="center">  
              <>
              <Tooltip title="View History" arrow>
              <IconButton onClick={()=>setShowHistory(row.secondryReqNumber)}>
             <FcClock />
          </IconButton>
          </Tooltip>
              {row.category==="Claim Intimation" && <> <Link to={`/lead/addremarks/${row._id}`}><Tooltip title="Add Changes" arrow>
              <IconButton>
             <FcSurvey />
          </IconButton>
          </Tooltip></Link>
          <Link to={`/lead/addcrop/${row._id}`}>
          <Tooltip title="Add Crops" arrow>
             <IconButton >
             <FcCloseUpMode />
          </IconButton>
          </Tooltip>
          </Link>
           </>}
          {row.category !=="Claim Intimation Changes" &&  <Tooltip title="Add Remarks" arrow onClick={()=>setShowRemarks(row._id)}>
             <IconButton >
             <FcWorkflow />
          </IconButton>
          </Tooltip> }
         
             </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
        <Suspense fallback={null}>
        <ViewHistory showRemark={showHistory} onClose={()=>setShowHistory("")}/>
        </Suspense>
        <Suspense fallback={null}>
        <AddRemarks showRemark={showRemarks} onClose={()=>setShowRemarks("")}/>
        </Suspense>
      </main>	
      <MySnackbar ref={snackRef} />
		</div>
	);
}

