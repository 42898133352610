import React,{useState,lazy,useRef,Suspense,useContext} from "react";
import TopNav from "../../Components/Navigation/TopNav"
import {
	makeStyles,
    Tabs,
    Card,
    TextField,
    Typography,
    MenuItem,
    Button,
    LinearProgress,
    Box,
    Tab,
    Grid,
} from "@material-ui/core";
import MyDrawer from "../../Components/Navigation/MyDrawer";
import MySnackbar from "../../Components/MySnackbar";
import axios from "axios";
import Backdrop from "../../Components/Backdrop";
import { MainContext } from "../../Components/Context/MainContext";
const TabPanel1 = lazy(() => import("./TabPanel1"));
const TabPanel2 = lazy(() => import("./TabPanel2"));
const TabPanel3 = lazy(() => import("./TabPanel3"));
const TabPanel4 = lazy(() => import("./TabPanel4"));
const TabPanel5 = lazy(() => import("./TabPanel5"));
const TabPanel6 = lazy(() => import("./TabPanel6"));


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left bottom, #ffbee3, #f3bdec, #e4bef3, #d2bff9, #bec0fc, #b0c8ff, #a3cfff, #9ad5ff, #9fe2fd, #adedfa, #c0f6f7, #d6fff7)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    },
    tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  workspace:{
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    borderRadius:10,
    border:"1px solid #e896d7"
  }
 
}));


export default function KnowledgeSheet() {
	const classes = useStyles();
  // const [file, setFile]= useState({});
  const [progress,setProgress]=useState(0)
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [file, setFile]= useState({});
  const [uploadZone,setUploadZone]=useState("")
	const snackRef = useRef();
	const { state } = useContext(MainContext);
  const designation = state.designation
 const handleLeads = async (e) => {
        e.preventDefault();
        setProgress(0);
		if (file) {
      setLoading(true)
      let url="";
    if(uploadZone==="Premium & SI Chart"){
      url = "/api/extra/knowledgeSheet/premiumSi/importPremium/saveMe"
    }else if(uploadZone==="Notified Locations & Crop"){
       url ="/api/extra/knowledgeSheet/cropLocation/importCropLocation/saveMe"
    }else if(uploadZone ==="RGI Officials District wise"){
       url = "/api/extra/knowledgeSheet/rgiOfficial/importRgiOfficial/saveMe"
    }else if(uploadZone==="Other Insurance Company"){
       url="/api/extra/knowledgeSheet/otherInsCom/importOtherInsCom/saveMe"
    }else if(uploadZone ==="Agri official blockwise"){
       url="/api/extra/knowledgeSheet/agriOfficial/importAgriOfficial/saveMe"
    }else if(uploadZone ==="Regional Crop Name"){
     url="/api/extra/knowledgeSheet/regionalName/importRegionalName/saveMe"
    } 
    if(url){
      const selectedFile = file;
			const fileData = new FormData();
			fileData.append("photo", selectedFile, selectedFile.name);
        await axios
				.post(url, fileData, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${fileData._boundary}`,
					},
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                        setProgress(Math.round((100 * data.loaded) / data.total))
                      },
				})
				.then((res) => {snackRef.current.handleSnack(res.data);
           setLoading(false)})
				.catch((err) => {console.log(err); setLoading(false)});
		}
    }else snackRef.current.handleSnack({message:"Please Select Upload Area"})
			
	};
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
                <Grid container spacing={2}>
                    {designation==="Admin" && 
                    <Grid item xs={12}>
                      <Card>
                        <form onSubmit={(e) => handleLeads(e)} className="center">
                          <Grid container spacing={2} style={{padding:16}}>
                            <Grid item xs={12}>
                            {
                progress !== 0 && 
                <>
                <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>   
    <br/>   
    </> 
            }
                            </Grid>
                          <Grid item xs={4}>
                              <TextField fullWidth required select label="Select Upload Section" value={uploadZone} onChange={e=>{setUploadZone(e.target.value)}}>
										{tabs.map((d) => (
											<MenuItem key={d.label} value={d.label}>
												{d.label}
											</MenuItem>
										))}
									</TextField>
                          </Grid>
                          <Grid item xs={4}>
                             <TextField
					variant="outlined"
					type="file"
					InputLabelProps={{ shrink: true }}
					inputProps={{ accept: ".xlsx,.csv,application/vnd.ms-excel/*" }}										
					label="Import Files"
                    required
                    onChange={e=> setFile(e.target.files[0])}
					/>
                          </Grid>
                          <Grid item xs={4} className="center">
                            <Button type="submit" variant="contained" color="primary">
							Import
					    </Button>
                          </Grid>
                        </Grid>
                        </form>
                      </Card>
                    </Grid>}
                    <Grid item xs={12} md={3}>
                        <Tabs value={tab}  orientation="vertical"
        variant="scrollable"  className={classes.tabs} onChange={(e,v)=>setTab(v)} aria-label="simple tabs example">
            {tabs.map((t,i)=><Tab key={i} label={t.label} /> )}
        </Tabs>
                 </Grid>
                    <Grid item xs={12} md={9} className={classes.workspace}>
                        {tab ===0 &&   <Suspense fallback={null}> <TabPanel1 snackRef={snackRef}/></Suspense> }
                        {tab ===1 &&   <Suspense fallback={null}> <TabPanel2 snackRef={snackRef}/></Suspense> }
                        {tab ===2 &&   <Suspense fallback={null}> <TabPanel3 snackRef={snackRef}/></Suspense> }
                        {tab ===3 &&   <Suspense fallback={null}> <TabPanel4 snackRef={snackRef}/></Suspense> }
                        {tab ===4 &&   <Suspense fallback={null}> <TabPanel5 snackRef={snackRef}/></Suspense> }
                        {tab ===5 &&   <Suspense fallback={null}> <TabPanel6 snackRef={snackRef}/></Suspense> }
                        {/* {tab ===1 && <TabPanel2 snackRef={snackRef}/> } */}
                    </Grid>
                </Grid>
         <MySnackbar ref={snackRef} />
 <Backdrop open={loading} />
            </div>
            
      </main>	
		</div>
	);
}


const tabs=[{label:"Premium & SI Chart"},{label:"Notified Locations & Crop"},{label:"RGI Officials District wise"},{label:"Other Insurance Company"},{label:"Agri official blockwise"},{label:"Regional Crop Name"},]