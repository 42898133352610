import React,{useRef,useContext} from 'react'
import IdleTimer from "react-idle-timer"
import { LOGOUT_USER } from "./Context/types";
import { MainContext } from "./Context/MainContext";

export default function MyIdleTimer() {
    const idleTimerRef =useRef(null);
	const {dispatch } = useContext(MainContext);

    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={1000*60*10} onIdle={()=> {dispatch({ type: LOGOUT_USER }); window.location.reload()}}/>
        </div>
    )
}
