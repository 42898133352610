import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../Login/Login.jsx";
import { MainContext } from "../Context/MainContext";
import Dashboard from "../../Protected/Dashboard";
import Users from "../../Protected/Users";
import CallAlert from "../../Protected/CallAlert";
import SRCreation from "../../Protected/SR/SRCreation";
import LocationMaster from "../../Protected/LocationMaster";
import CscLocator from "../../Protected/CscLocator";
import CheckClaimStatus from "../../Protected/CheckClaimStatus";
import KnowledgeSheet from "../../Protected/KnowledgeSheet/KnowledgeSheet";
import Script from "../../Protected/Script";
import Search from "../../Protected/Search/Search";
import Import from "../../Protected/Import"
import Export from "../../Protected/Export"
import RecordingData from "../../Protected/RecordingData";

// import Backdrop from "../Backdrop";


const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	const { state } = useContext(MainContext);
	let isAuthenticated = state.isAuthenticated && (state.designation === "Admin" || state.designation === "User" ||  state.designation === "Supervisor"  ||  state.designation === "Recording") ? true : false;
	return <Route {...rest} render={(props) => (isAuthenticated === true ? <Component {...props} /> : <Route to={{pathname:"/",state:{from:props.location}}} />)} />;
};

export default function MainRoute() {
	return (
		<Switch>
			<Route path="/" exact component={Login} />
			<Route path="/login" exact component={Login} />
			<PrivateRoute exact path={`/dashboard`} component={Dashboard} />
			<PrivateRoute exact path={`/users`} component={Users} />
			<PrivateRoute exact path={`/callalert`} component={CallAlert} />
			<PrivateRoute exact path={`/import`} component={Import} />
			<Route path="/lead">
				<Switch>
					<Route exact path="/lead" component={SRCreation} />
					<Route exact path="/lead/:page/:id" component={SRCreation} />
				</Switch>
			</Route>
			<PrivateRoute exact path={`/location`} component={LocationMaster} />
			<PrivateRoute exact path={`/csclocator`} component={CscLocator} />
			<PrivateRoute exact path={`/RecordingData`} component={RecordingData} />
			<PrivateRoute exact path={`/claimstatus`} component={CheckClaimStatus} />
			<PrivateRoute exact path={`/knowledgesheet`} component={KnowledgeSheet} />
			<PrivateRoute exact path={`/script`} component={Script} />
			<PrivateRoute exact path={`/search`} component={Search} />
			<PrivateRoute exact path={`/export`} component={Export} />
			<Route path="*" component={()=>"404 Not Found"} />
		</Switch>
	);
}


