import React,{useState,useRef} from "react";
import TopNav from "../Components/Navigation/TopNav"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	makeStyles,
    Grid,
    FormControlLabel,
    Switch,
    Card,
    MenuItem,
    Button,
    Divider,
    TextField,
    Typography
} from "@material-ui/core";
import {allCateg} from "./SR/SRCreation"
import MyDrawer from "../Components/Navigation/MyDrawer";
import { DateRangePicker } from 'react-date-range';
import MySnackbar from "../Components/MySnackbar"
import axios from "axios"
import Backdrop from "../Components/Backdrop";

// import { FcSynchronize} from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    workArea:{
        minHeight:400,
        padding:theme.spacing(2),
        backgroundImage: "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)"
    },
 
}));


export default function Export() {
	const classes = useStyles();
    const [startDate, setStartDate] = useState(new Date()) ;
    const [endDate, setEndDate] = useState(new Date()) ;
    const [crm, setMode]=useState(false);
    const [category,setCateg]=useState("")
	const snackRef = useRef();
	const [loading, setLoading] = useState(false);

    const selectionRange = {
        startDate:startDate,
        endDate:endDate,
        key:"selection"
    };
    function handleSelect(ranges) {
        setStartDate(ranges.selection.startDate);
        setEndDate( ranges.selection.endDate);
    }
  
    const handleExport = ()=> {
        if(crm && !category){
            snackRef.current.handleSnack({message:"Select any Category", variant:"info"});
        }else{
            setLoading(true)
            const today = new Date();
            const data = {startDate,endDate,today,crm,category};
              axios
                .post("/api/sr/exportSr/exportMe", data)
                .then(res => {
                    setLoading(false)
                  if(res.data.variant==="success"){
                    window.open(
                        res.data.link,
                        '_blank' 
                      );
                  }else snackRef.current.handleSnack({message:res.data.message, variant:res.data.variant});
                })
                .catch(err =>{ console.log(err); setLoading(false)});
        }
	};


	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.workArea}>
                <Card style={{padding:16}}>
            <Typography variant="body1" gutterBottom>Export Report</Typography>
            <Divider/>
            <Grid container>
                <Grid item xs={12} sm={8}>
                <DateRangePicker ranges={[selectionRange]} onChange={handleSelect}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <FormControlLabel
                    control={<Switch color="secondary" checked={crm} onChange={() => setMode(!crm)} />}
                    label={crm ?  "Export CRM" : "Export IVR" }
                    labelPlacement="start"
                     />
                    <Divider/>
                    <br/>
                    {crm &&  <>
                    <TextField fullWidth required select label="Select Category" value={category} onChange={(e) => setCateg(e.target.value)}>
                    <MenuItem value="All">All</MenuItem>
                                        {allCateg.map((d) => (
											<MenuItem key={d} value={d}>
												{d}
											</MenuItem>
										))}
									</TextField>
                                    <br/>
                                    <Divider/>
                                    </>}
                                 
                                    <br/>
                                    <div className="center">
                                    <Button
                variant="contained"
                color="primary"
                onClick={()=>handleExport()}
              >
              Export Data
              </Button>
                                    </div> 
                                    
                   
                </Grid>
            </Grid>
                </Card>
            </div>
            <Backdrop open={loading} />
            <MySnackbar ref={snackRef} />
      </main>	
		</div>
	);
}

