import React,{useRef} from "react";
import TopNav from "../Components/Navigation/TopNav"
import MyDrawer from "../Components/Navigation/MyDrawer";
import {
	makeStyles,
    Typography,
    Card,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Tooltip,
} from "@material-ui/core";
import MySnackbar from "../Components/MySnackbar";
import {FcEmptyTrash } from "react-icons/fc";
import SaveIncomp from "./SR/SaveIncomp" 


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
        padding: theme.spacing(2),
        backgroundImage: "linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)"
    },
    workArea:{
        padding:theme.spacing(2)
    },
    table:{
        minWidth: 650,
      },
    dense:{
          width:10
      },
    iconBtn:{
      display:"flex",
      flexDirection:"row",
      justifyContent:"center"
    }
}));


export default function CallAlert() {
	const classes = useStyles();
    
	const snackRef = useRef();

  
    // useEffect(() => {
	// 	axios
	// 		.get(`/api/blog/blogcat/get`)
	// 		.then((res) => setAllCat(res.data))
	// 		.catch((err) => console.log(err));
	// }, []);

    const handleDelete =(id)=>{
      var result = window.confirm("Are you Sure to Delete it ?")
       if(result){
        console.log(`Delete the ${id}`);
        snackRef.current.handleSnack({message:"Date Fetched", variant:"success"});
       } 
       
     

    }
    
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
        <Card className={classes.workArea}>
        <TableContainer component={Paper}>
                <br/>
                <Typography variant="subtitle2" color="secondary" align="center"> Call Status Report</Typography>
      <Table className={classes.table} aria-label="Employee table">
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell align="left">Caller Name</TableCell>
            <TableCell align="left">Call&nbsp;Time</TableCell>
            <TableCell align="left">Caller&nbsp;Status</TableCell>
            <TableCell align="left">Call&nbsp;Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {callList.map((row,i) => (
            <TableRow key={row.id}>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell align="left">{row.callerNumber}</TableCell>
              <TableCell align="left">{row.callTime}</TableCell>
              <TableCell align="left">{row.callerStatus}</TableCell>
              <TableCell align="left">{row.callStatus}</TableCell>
              <TableCell className={classes.iconBtn}>  
              <>
              <SaveIncomp load={null}/>
              <Tooltip title="Delete" arrow>
              <IconButton  size="medium" onClick={()=>handleDelete(row._id)}>
             <FcEmptyTrash />
          </IconButton>
          </Tooltip>
             </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        
        </Card>
      </main>	
      <MySnackbar ref={snackRef} />
		</div>
	);
}



const callList=[{id:"sdsdsd",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdsds",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdswerrdsd",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdsdsdsderetg",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdsdsd4545",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdsdsd24",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },
{id:"sdsdsdsdgtr",callerNumber:"9585652545",callTime:"9:00 AM", callerStatus:"Check", callStatus:"No Call", },]