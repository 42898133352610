import React,{Suspense,useState,useEffect, useContext,lazy} from "react";
import TopNav from "../../Components/Navigation/TopNav"
import { SRProvider, SRContext } from "../../Components/Context/SRContext";
import {SETSCHEME, SETCAT,SETSUBCAT, SHOWMORE,SETSEASON,SETSTATE,SETDISTRICT,SETBLOCK,SETGP,SETVILLAGE,NAYAPANCHAYAT,SETPIN,SETALLCROPS,SETCROP,SETIDSR,SETTBO,SETNPO,SETGPO,SETVO,SETCNO} from "../../Components/Context/types"
import {
	makeStyles,
    MenuItem,
    Grid,
    Collapse,
    Divider,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import MyDrawer from "../../Components/Navigation/MyDrawer";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClaimIntimation from "./ClaimIntimation"
import axios from "axios";
const SRForm2 = lazy(() => import("./SRForm2"));
const SRForm3 = lazy(() => import("./SRForm3"));

// import { MdFastForward} from "react-icons/md";

const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "radial-gradient(circle, rgba(174,238,219,0.5749650201877626) 0%, rgba(210,227,247,0.4125000341933649) 100%)",
        minHeight:300,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    },
    formArea:{
        margin:theme.spacing(),
        padding:theme.spacing(1,2)

    },
    actionsContainer: {
		marginBottom: theme.spacing(2),
	},
 
}));


function Creation({match}) {
   const classes = useStyles();
   const {SRState, SRDispatch } = useContext(SRContext);
   const [pin, setPin] = useState("")
   const [allScheme,setScheme]= useState([]);
   const [allSubCateg, setAllSubCat]=useState([]);
   const [allSeason,setSeason]=useState([])
   const [allState,setAllState] = useState([]);
   const [allDist,setDist] = useState([]);
   const [allBlock,setBlock] =useState([]);
   const [allGP,setGP] = useState([]);
   const [allVillage,setVillage]=useState([])
   const [showForm, setShowForm]=useState(0)
   const [pinError, setPinError]=useState(false)
   const [pinErrorMsg, setPinErrorMsg]=useState("6 Digit PIN Only")
//    changes by vivek to enable other options
   const [tahsilBlockOther,setTahsilBlockOther]=useState("");
   const [nayaPanchayatOther,setnayaPanchayatOther]=useState("");
   const [nayaPanchayatOtherReadOnly,setnayaPanchayatOtherReadOnly]=useState(false);
   const [showBlockOther, setshowBlockOther]=useState(false);
   const [gramPanchayatOther,setgramPanchayatOther]=useState("");
   const [showGpOther, setshowGpOther]=useState(false);
   const [villageOther,setvillageOther]=useState("");
   const [showVillageOther, setshowVillageOther]=useState(false);
   const [cropNameOther,setcropNameOther]=useState("");
   const [showCropOther, setshowCropOther]=useState(false);
   useEffect(() => {
    if(SRState.tahsilBlock==="Others"){
     setshowBlockOther(true)
    }else setshowBlockOther(false)
  }, [SRState.tahsilBlock])
   useEffect(() => {
    if(SRState.gramPanchayat==="Others"){
        setshowGpOther(true)
    }else setshowGpOther(false)
  }, [SRState.gramPanchayat])
   useEffect(() => {
    if(SRState.village==="Others"){
        setshowVillageOther(true)
    }else setshowVillageOther(false)
  }, [SRState.village])
   useEffect(() => {
    if(SRState.cropName==="Others"){
        setshowCropOther(true)
    }else setshowCropOther(false)
  }, [SRState.cropName])
  const setOtherData=(data)=>{
    setTahsilBlockOther(data.tahsilBlockOther)
    setnayaPanchayatOther(data.nayaPanchayatOther)
    setgramPanchayatOther(data.gramPanchayatOther)
    setvillageOther(data.villageOther)
    setcropNameOther(data.cropNameOther)
}
const sendingOtherInContext = () => {
    if(SRState.tahsilBlock === "Others") {
        SRDispatch({type:SETTBO,payload:tahsilBlockOther}) 
        SRDispatch({type:SETNPO,payload:nayaPanchayatOther}) 
   
    }
    if(SRState.gramPanchayat==="Others"){
    SRDispatch({type:SETGPO,payload:gramPanchayatOther}) 
    }
    if(SRState.village==="Others"){
    SRDispatch({type:SETVO,payload:villageOther}) 
    }
    if(SRState.cropName==="Others"){
    SRDispatch({type:SETCNO,payload:cropNameOther}) 
    }

}
const handleChange=(e,p,v)=>{
    if(v==="text"){
       var re = /^[A-Za-z_ ]*$/;
       if (e === '' || re.test(e)) {
          switch (p) {
             case "tahsilBlockOther":
                setTahsilBlockOther(e)
                break;
             case "nayaPanchayatOther":
                setnayaPanchayatOther(e)
                break;
             case "gramPanchayatOther":
                setgramPanchayatOther(e)
                break;
             case "villageOther":
                setvillageOther(e)
                break;
             case "cropNameOther":
                setcropNameOther(e)
                break;
             default:
                break;
          }
       }

    }
   
}
//VIvek Edit End

   useEffect(() => {
     if(match.params.id){
        let {id,page}=match.params
        axios.get(`/api/sr/getSr/get/${page}/${id}`).then(res=>{
            if(res.data){
                setOtherData(res.data)
                setPin(res.data.pin)
                SRDispatch({type:SETIDSR,payload:{...res.data,page,id}}) 
            }
        }).catch(err=>console.log(err))
     }
     
   }, [match,SRDispatch])
   useEffect(() => {
    switch (SRState.category) {
        case "Claim Intimation":
            setAllSubCat(["Claim Intimation"])
            SRDispatch({ type: SETSUBCAT, payload:"Claim Intimation" })
            break;
        case "Complaint":
            setAllSubCat(["Non Claim Related","Claim Related"])
            break;
        case "Claim Status":
            setAllSubCat(["Claim Related- Claim Payment not received","Claim Related - Survey status related","Claim Related - Less claim amount received","Claim Related - Claim Inquiry but no intimation","Claim Related - Reference Number Required", "Claim Related- Payment re-issuance in different account"])
            break;
        case "Non Crop Insurance":
            setAllSubCat(["Non Crop Insurance"])
            SRDispatch({ type: SETSUBCAT, payload:"Non Crop Insurance" })
            break;
        case "Inquiry":
                setAllSubCat(["Claim Related - How to Lodge Claim","State/District/Crop Not Found", "Non Claim related" ])
                break;
        case "Enrollment":
            setAllSubCat(["Enrolment related – Scheme inquiry","Enrolment related - Portal technical error","Enrolment related - Portal application correction / Updation","Enrolment related - Application Status Inquiry","Enrolment related - Policy no. required"])
                break;
        case "Inland Letter":
            setAllSubCat(["Inland Letter inquiry","Inland Letter- Changes Required"])
            break;
       
        case "Insufficient Info":
            setAllSubCat(["Insufficient Info"])
            SRDispatch({ type: SETSUBCAT, payload:"Insufficient Info" })
            break;
        case "Language Barrier":
            setAllSubCat(["Language Barrier"])
            SRDispatch({ type: SETSUBCAT, payload:"Language Barrier" })
            break;
        case "Call Dropped":
            setAllSubCat(["Call Dropped"])
            SRDispatch({ type: SETSUBCAT, payload:"Call Dropped" })
            break;
        case "Old Intimation":
            setAllSubCat(["Old Intimation"])
            SRDispatch({ type: SETSUBCAT, payload:"Old Intimation" })
            break;
        default:
            break;
    }
}, [SRState.category, SRDispatch]);
    useEffect(() => {
        if(SRState.subCat !== ""){
            SRDispatch({ type: SHOWMORE, payload:true })
        }else SRDispatch({ type: SHOWMORE, payload:false })
    }, [SRState.subCat, SRDispatch])

    useEffect(() => {
        axios
        .get("/api/location/getLocation/schemeName")
        .then((res) => setScheme(res.data))
        .catch((err) => console.log(err));
    }, [])
    useEffect(() => {
        if(SRState.schemeName && SRState.seasonName){
            axios
            .post("/api/location/getLocation/state",{schemeName:SRState.schemeName,seasonName:SRState.seasonName})
            .then((res) => setAllState(res.data))
            .catch((err) => console.log(err));   
        }
    }, [SRState.schemeName,SRState.seasonName])
    useEffect(() => {
        if(SRState.schemeName){
            axios
            .post("/api/location/getLocation/seasonName",{schemeName:SRState.schemeName})
            .then((res) => setSeason(res.data))
            .catch((err) => console.log(err));   
        }
    }, [SRState.schemeName])

    const getDist=()=>{
        if(SRState.schemeName && SRState.seasonName && SRState.state){
            axios
            .post("/api/location/getLocation/districtName",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state})
            .then((res) => setDist(res.data))
            .catch((err) => console.log(err));   
        }
    }
    const getBlock=()=>{
        if(SRState.schemeName && SRState.seasonName && SRState.state && SRState.districtName){
            axios
            .post("/api/location/getLocation/tahsilBlock",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state,districtName:SRState.districtName})
            .then((res) => setBlock(res.data))
            .catch((err) => console.log(err));   
        }
    }
   const checkIfOther = () => {
    if(SRState.tahsilBlock === "Others"){
        setnayaPanchayatOther("Others")
        setnayaPanchayatOtherReadOnly(true)
    } else {
        setnayaPanchayatOther("") 
        setnayaPanchayatOtherReadOnly(false)

    }

   }
   function allPinSame(s)
   {
       let n = s.length;
       let t = 0
       for (let i = 1; i < n; i++){
           if (s[i] === s[0]){
               t = t + 1
            }
            }
            if(s ===  '1234567890' || pin === '123456' || s === "12345" || t >= (n-1)){
                setPinError(true)
                setPinErrorMsg("Invalid PIN")
               return true;
            } 
            if(s.length >=5)
            {
            setPinError(false)
        }
            setPinErrorMsg("6 Digit PIN Only")
       return false;

   }
    const getGP=()=>{
        if(SRState.schemeName && SRState.seasonName && SRState.state && SRState.districtName &&SRState.tahsilBlock){
            axios
            .post("/api/location/getLocation/grampanchayat",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state,districtName:SRState.districtName,tahsilBlock:SRState.tahsilBlock})
            .then((res) => setGP(res.data))
            .catch((err) => console.log(err));   
        }
    }
    const getVillage=()=>{
        if(SRState.schemeName && SRState.seasonName && SRState.state && SRState.districtName && SRState.tahsilBlock && SRState.gramPanchayat){
            axios
            .post("/api/location/getLocation/village",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state,districtName:SRState.districtName,tahsilBlock:SRState.tahsilBlock,gramPanchayat:SRState.gramPanchayat})
            .then((res) => setVillage(res.data))
            .catch((err) => console.log(err));   
        }
    }
    const getNayaPanc= async() =>{
        if(SRState.schemeName && SRState.seasonName && SRState.state && SRState.districtName && SRState.tahsilBlock && SRState.gramPanchayat && SRState.village ){
         await axios
            .post("/api/location/getLocation/nayaPanchayat",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state,districtName:SRState.districtName,tahsilBlock:SRState.tahsilBlock,gramPanchayat:SRState.gramPanchayat,village:SRState.village})
            .then((res) =>{
                if(res.data){
                    SRDispatch({ type: NAYAPANCHAYAT,payload:res.data})
                }
            }
            )
            .catch((err) => console.log(err));   
            axios
            .post("/api/location/getLocation/cropName",{schemeName:SRState.schemeName,seasonName:SRState.seasonName,state:SRState.state,districtName:SRState.districtName,tahsilBlock:SRState.tahsilBlock,gramPanchayat:SRState.gramPanchayat,village:SRState.village})
            .then((res) => {
                if(res.data){
                    SRDispatch({type:SETALLCROPS,payload:res.data})
                }
            })
            .catch((err) => console.log(err));   
        }
    }
  
    useEffect(() => {
       if( SRState.cropName && pin.length===6 && !pinError ){
           
         
            sendingOtherInContext()   
            SRDispatch({type:SETPIN,payload:pin})
           if(SRState.subCat==="Claim Intimation" || SRState.subCat==="Claim Related"){
            setShowForm(1) 
           }else if(SRState.subCat &&  (SRState.subCat === "Inland Letter- Changes Required" || SRState.subCat === "Non Claim Related" || SRState.category==="Claim Status"  )){
           setShowForm(3) 
            }else if(SRState.subCat){
            setShowForm(2)
            }else setShowForm(0)
     
       }else setShowForm(0)
    }, [SRState.cropName,SRState.subCat, SRState.category, pin,SRDispatch])
    const handlePin=(e)=>{
        const re = /^[0-9\b]+$/;
        if (e === '' || re.test(e)) {
           setPin(e)
        }
        allPinSame(pin)
    }

	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
            <Grid container spacing={4}>
               <Grid item xs={12} className={classes.importBox}>
                   <Grid container spacing={2} justify="center">
                       <Grid item xs={6} md={3}>
                       <TextField fullWidth required select label="Select Category" value={SRState.category} disabled={match.params.page} onChange={(e) => {SRDispatch({ type: SETCAT, payload: e.target.value }); SRDispatch({ type: SETSUBCAT, payload:"" })}}>
										{allCateg.map((d) => (
											<MenuItem key={d} value={d}>
												{d}
											</MenuItem>
										))}
									</TextField>
                       </Grid>
                       <Grid item xs={6} md={3}>
                       <TextField fullWidth required select label="Select Sub Category" value={SRState.subCat} disabled={match.params.page} onChange={(e) => SRDispatch({ type: SETSUBCAT, payload:e.target.value })}>
							{allSubCateg.map((d) => (
								<MenuItem key={d} value={d}>
									{d}
								</MenuItem>
								))}
						</TextField>
                       </Grid>
                       </Grid>
                       <br/>
                       <Divider/>
                       <br/>
                       <Collapse in={SRState.showMore}>
                       <Grid container spacing={2} justify="center">
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allScheme}
      onChange={(e, v) => SRDispatch({ type: SETSCHEME, payload:v })}
      getOptionLabel={(option) => option}
      value={SRState.schemeName} 
      renderInput={(params) => <TextField {...params} placeholder="Type Scheme" label="SEARCH SCHEME" />}
    />
                       </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allSeason}
      onChange={(e, v) => SRDispatch({ type: SETSEASON, payload:v })}
      getOptionLabel={(option) => option}
      value={SRState.seasonName} 
      disabled={!SRState.schemeName}
      renderInput={(params) => <TextField {...params} placeholder="Type Season" label="SEARCH SEASON" />}
    />

                       </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allState}
      onChange={(e, v) => {setDist([]);SRDispatch({ type: SETSTATE, payload:v }); getDist()}}
      getOptionLabel={(option) => option}
      value={SRState.state}
      disabled={!SRState.seasonName}
      renderInput={(params) => <TextField {...params} placeholder="Type State"  label="SEARCH STATE" />}
    />
                       </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allDist}
      onChange={(e, v) => {setBlock([]);SRDispatch({ type: SETDISTRICT, payload:v });getBlock()}}
      getOptionLabel={(option) => option}
      value={SRState.districtName}
       disabled={!SRState.state}
      renderInput={(params) => <TextField {...params} placeholder="Type District" label="SEARCH DISTRICT" />}
    />

                       </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allBlock}
      onChange={(e, v) => {setGP([]);SRDispatch({ type: SETBLOCK, payload:v });getGP();checkIfOther()}}
      getOptionLabel={(option) => option}
      value={SRState.tahsilBlock} 
        disabled={!SRState.districtName}
      renderInput={(params) => <TextField {...params} placeholder="Type Block" label="SEARCH BLOCK/TEHSIL" />}
    />
                       </Grid>
                       <br/>
                       <Divider/>
                       <br/>
                  
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allGP}
      getOptionLabel={(option) => option}
      onChange={(e, v) => {setVillage([]); SRDispatch({ type: SETGP, payload:v });getVillage()}}
      value={SRState.gramPanchayat}
        disabled={!SRState.tahsilBlock}
      renderInput={(params) => <TextField {...params}   placeholder="Type GP" label="Search Gram Panchayat" />}
    />
                       </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={allVillage} 
      getOptionLabel={(option) => option}
      onChange={(e, v) => {SRDispatch({ type: SETVILLAGE, payload:v });getNayaPanc()}}
      value={SRState.village}
       disabled={!SRState.gramPanchayat}
      renderInput={(params) => <TextField {...params}  placeholder="Type Village" label="Search Village" />}
    />
                        
                        </Grid>
                       <Grid item xs={6} md={3}>
                       <Autocomplete
      options={SRState.allCrops}
      getOptionLabel={(option) => option}
      value={SRState.cropName} 
        disabled={!SRState.village}
      onChange={(e, v) => SRDispatch({ type: SETCROP, payload:v })}
      renderInput={(params) => <TextField {...params}   placeholder="Search Crop Name" label="Notified Crop" />}
    />  </Grid>
    {/* <Collapse in={showBlockOther}> */}
                       
                       <Grid item xs={6} md={2}>
        <Collapse in={showBlockOther}>
        <TextField fullWidth value={tahsilBlockOther} onChange={e=>handleChange(e.target.value,"tahsilBlockOther","text")} placeholder="Enter other Tahsil/Block" label="Enter other Block"  />
        </Collapse>
   
       
        </Grid>
                       <Grid item xs={6} md={2}>
        <Collapse in={showBlockOther}>
        <TextField    
        InputProps={{
            readOnly: nayaPanchayatOtherReadOnly,
          }}
          fullWidth value={nayaPanchayatOther} onChange={e=>handleChange(e.target.value,"nayaPanchayatOther","text")} placeholder="Enter other Np" label="Enter other Np"  />
        </Collapse>
   
       
        </Grid>
                       <Grid item xs={6} md={2}>
        <Collapse in={showGpOther}>
        <TextField fullWidth value={gramPanchayatOther} onChange={e=>handleChange(e.target.value,"gramPanchayatOther","text")} placeholder="Enter other Gp" label="Enter other Gp"  />
        </Collapse>
   
       
        </Grid>
                       <Grid item xs={6} md={2}>
        <Collapse in={showVillageOther}>
        <TextField fullWidth value={villageOther} onChange={e=>handleChange(e.target.value,"villageOther","text")} placeholder="Enter other Village" label="Enter other Village"  />
        </Collapse>
   
       
        </Grid>
                       <Grid item xs={6} md={2}>
        <Collapse in={showCropOther}>
        <TextField fullWidth value={cropNameOther} onChange={e=>handleChange(e.target.value,"cropNameOther","text")} placeholder="Enter other Crop Name" label="Enter other Crop Name"  />
        </Collapse>
   
       
        </Grid>
        {/* </Collapse> */}
    
                        <Grid item xs={6} md={2}>
                       <TextField label={pinError ? pinErrorMsg: "PIN Code"} disabled={
                           !((SRState.cropName)&&
                           ((SRState.tahsilBlock==="Others"&& tahsilBlockOther && nayaPanchayatOther)|| (SRState.tahsilBlock!=="Others"))&&
                           ((SRState.gramPanchayat==="Others"&& gramPanchayatOther)|| (SRState.gramPanchayat!=="Others"))&&
                           ((SRState.village==="Others"&& villageOther)|| (SRState.village!=="Others"))&&
                           ((SRState.cropName==="Others"&& cropNameOther)|| (SRState.cropName!=="Others"))
                           )
                           
                           } onBlur={()=>(pin.length !==6) ? setPinError(true): setPinError(false)} inputProps={{ maxLength: "6" }} error={pinError} value={pin}  placeholder="6 Digit" onChange={e=>handlePin(e.target.value)} />
                        </Grid>
                        </Grid>
                       </Collapse> 
               </Grid>
            </Grid>
        
            </div>
            {showForm=== 1 ?  <ClaimIntimation/> : showForm=== 2 ? <SRForm2/> : showForm ===3 ? <SRForm3/> : null}
           
        
            
      </main>	
		</div>
	);
}

export const allCateg= ["Claim Intimation","Complaint","Claim Status","Non Crop Insurance", "Inquiry","Enrollment", "Inland Letter", "Insufficient Info", "Language Barrier", "Call Dropped","Old Intimation" ];


export default function SRCreation({match}) {
	return (
		<SRProvider>
			<Suspense
				fallback={
					<div className="center" style={{ minHeight: 400 }}>
						<CircularProgress />
					</div>
				}
			>
				<Creation match={match}/>
                <Prompt message="Are you sure you want to leave SR Creation?" />
			</Suspense>
		</SRProvider>
	);
}
