import React,{useState} from "react";
import TopNav from "../Components/Navigation/TopNav"
import {
	makeStyles,
    Grid,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card
} from "@material-ui/core";
import MyDrawer from "../Components/Navigation/MyDrawer";
// import { FcSynchronize} from "react-icons/fc";
// import axios from "axios"

const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left bottom, #ffbee3, #f3bdec, #e4bef3, #d2bff9, #bec0fc, #b0c8ff, #a3cfff, #9ad5ff, #9fe2fd, #adedfa, #c0f6f7, #d6fff7)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    }
 
}));


export default function CheckClaimStatus() {
	const classes = useStyles();
    const [rows]=useState([{seasonName:"SSDHKD",state:"Bihar",districtName:"Araria",tahsilBlock:"Block",farmerName:"Vivek Kumar",fatherName:"Test",refNo:"5456454",application:"551515", kcc:"5451515",khata:"654564545",affectedCrop:"51s5dsd",status:"Success"}])
    const handleLeads = async (e) => {
        e.preventDefault();
		
	};
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
            <Grid container spacing={4}>
               <Grid item xs={12} className={classes.importBox}>
               <form onSubmit={(e) => handleLeads(e)} className="center">
               <TextField
					variant="outlined"	
                    style={{width:400}}		
                    placeholder="Type Ref No./Applicant No./Survey No."							
					label="Search by Ref No./Applicant No./Survey No."
                    required
					/>
                    <span style={{flexGrow:0.05}}/>
                         <Button type="submit" variant="contained" color="primary">
							Search
					    </Button>
                   </form>
               </Grid>
               <Grid item xs={12}>
                   <Card style={{padding:16}}>
                  <Table size="small" aria-label="Search-table" >
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell size="small" align="left">Season Name </TableCell>
            <TableCell size="small" align="left">State</TableCell>
            <TableCell size="small" align="left">District</TableCell>
            <TableCell size="small" align="center">Block</TableCell>
            <TableCell size="small" align="center">Farmer Name</TableCell>
            <TableCell size="small" align="center">Father Name</TableCell>
            <TableCell size="small" align="center">Ref No.</TableCell>
            <TableCell size="small" align="center">Applicant No. </TableCell>
            <TableCell size="small" align="center">Ac/KCC No.</TableCell>
            <TableCell size="small" align="center">Survey No.</TableCell>
            <TableCell size="small" align="center">Affected Crop</TableCell>
            <TableCell size="small" align="center">Claim Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell size="small" align="left"> {row.seasonName}</TableCell>
              <TableCell size="small" align="left">{row.state}</TableCell>
              <TableCell size="small" align="left">{row.districtName}</TableCell>
                <TableCell size="small" align="center">{row.tahsilBlock}</TableCell>
                <TableCell size="small" align="center">{row.farmerName}</TableCell>
                <TableCell size="small" align="center">{row.fatherName}</TableCell>
                <TableCell size="small" align="center">{row.refNo}</TableCell>
                <TableCell size="small" align="center">{row.application}</TableCell>
                <TableCell size="small" align="center">{row.kcc}</TableCell>
                <TableCell size="small" align="center">{row.khata}</TableCell>
                <TableCell size="small" align="center">{row.affectedCrop}</TableCell>
                <TableCell size="small" align="center">{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
              </Card>
                
                </Grid> 
             
            </Grid>
            </div>
            
      </main>	
		</div>
	);
}

