import React,{useState,useEffect} from "react";
// import Link from '@mui/material/Link';
import TopNav from "../Components/Navigation/TopNav"
import {
	makeStyles,
    Grid,
    TextField,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MyDrawer from "../Components/Navigation/MyDrawer";
import axios from "axios";

// import { FcSynchronize} from "react-icons/fc";
// import axios from "axios"

const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left bottom, #ffbee3, #f3bdec, #e4bef3, #d2bff9, #bec0fc, #b0c8ff, #a3cfff, #9ad5ff, #9fe2fd, #adedfa, #c0f6f7, #d6fff7)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    }
 
}));


export default function CheckClaimStatus() {
	const classes = useStyles();
    const [searchWord,setSearchWord] = useState("")
    const [allDate,setAllDate] = useState([])
    const [oneDate,setOneDate] = useState("")
    const [totalRecData,setTotalRecData] = useState("Counting...")
    const [rows,setRow] = useState(
        [
        //   {
        //     "previousLink": "",
        //     "newLink": "",
        //     "urlDate": "",
        //     "urlTime": "",
        //     "urlMobileNo": "",
        //     "_id": "",
        //     "user": "",
        //     "date": "",
        // }
        ]
        )
    const handleLeads = async (e) => {
        e.preventDefault();		
	};

  useEffect(() => {
    axios
    .get(`/api/upload/uploadAudio/get/alldates`)
    .then((res) => 
      
        setAllDate(res.data)
   
    )
    .catch((err) => console.log(err));
  },[] );

    const getAllData =(searchWord)=>{
      let searching = ""
      if(searchWord){
        searching = "searching"
      }
      axios
        .post(`/api/upload/uploadAudio/get/allrec/${searching}`,{searchWord})
        .then((res) => setRow(res.data))
        .catch((err) => console.log(err));
  
      }

        useEffect(() => {
          getAllData(searchWord)
      }, [searchWord]);

    const getTotalRecCount =()=>{
        axios
        .get(`/api/upload/uploadAudio/get/uploadcount`)
        .then((res) => setTotalRecData(res.data.totalNumber))
        .catch((err) => console.log(err));
      }
      getTotalRecCount()

    const getDataWithDate =(date)=>{

      if(date){  
        axios
        .post(`/api/upload/uploadAudio/get/getbyDate`,{urlDate:date})
        .then((res) => setRow(res.data))
        .catch((err) => console.log(err));
      }
      }
 
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
            <Grid container spacing={4}>
               <Grid item xs={12} className={classes.importBox}>
               <form onSubmit={(e) => handleLeads(e)} className="center">
               <TextField
					variant="outlined"	
                    style={{width:1000}}		
                    placeholder="Type mobile number,unique id, previous link"							
					label="Search by date, time,unique id"
                    value={searchWord}
                    onChange={e=>{setSearchWord(e.target.value)}}
                    required
					/>
                    <span style={{flexGrow:0.05}}/>
                        
                   </form>
               </Grid>
               <Grid item xs={6}>
               <Autocomplete
      options={allDate}
      onChange={(e, v) => {
        if(v){
        setOneDate(v);getDataWithDate(v)}
      }}
      getOptionLabel={(option) => option}
      value={oneDate} 
      renderInput={(params) => <TextField {...params} placeholder="Type Date" label="Filter With Date" />}
    />
               </Grid>
               <Grid item xs={6}>
                <h1>total Recording Data : {totalRecData}</h1> 
              
               </Grid>
               {/* <Grid item xs={2}>
                <Tooltip title="Refresh" arrow>
                <IconButton aria-label="refresh" style={{marginRight:15}}>
          <FcSynchronize style={{fontSize:20}}/>
        </IconButton>
        </Tooltip>
               </Grid> */}
               <Grid item xs={12}>
               { 
              (rows.length === 0 ) &&
               (<Card style={{padding:16}}>
                 <h1>             No Data Found.</h1>
               </Card>)
              }
                   <Card style={{padding:16}}>
                  <Table size="small" aria-label="Search-table" >
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell size="small" align="left">Previous Link</TableCell>
            <TableCell size="small" align="left">Date</TableCell>
            <TableCell size="small" align="left">Time</TableCell>
            <TableCell size="small" align="center">Mobile No</TableCell>
            <TableCell size="small" align="center">New Link</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell size="small" align="left"> {row.previousLink}</TableCell>
              <TableCell size="small" align="left">{row.urlDate}</TableCell>
              <TableCell size="small" align="left">{row.urlTime}</TableCell>
                <TableCell size="small" align="center">{row.urlMobileNo}</TableCell>
                <TableCell size="small" align="center"> <Button href={row.newLink} target="_blank" variant="contained" color="primary">
							New Link
					    </Button></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
              </Card>
      
                </Grid> 
           
            </Grid>
            </div>
            
      </main>	
		</div>
	);
}




