import React,{useContext} from "react";
import {
	makeStyles,
    AppBar,
    Hidden,
    Toolbar,IconButton,Avatar
	
} from "@material-ui/core";
import { FcMenu } from "react-icons/fc";
import clsx from 'clsx';
import { MainContext } from "../Context/MainContext";
import {DRAWER} from "../Context/types"


const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
      marginLeft: -20,
      },
      hide: {
        display: 'none',
      },
      grow:{
          flexGrow:1
      }
	
}));


export default function TopNav() {
	const classes = useStyles();
	const { state, dispatch } = useContext(MainContext);
    const handleDrawerToggle = () => {
		dispatch({ type: DRAWER });
	};
	return (
        <AppBar position="fixed" color="inherit" className={clsx(classes.appBar, {
            [classes.appBarShift]: state.mobileDrawer,
          })}>
                 <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: state.mobileDrawer,
            })}
          >
            <FcMenu />
          </IconButton>
          <Hidden smDown implementation="css">
          <a href="/dashboard">
          <img style={{width:240}} href="/dashboard"  src="https://www.reliancegeneral.co.in/SiteAssets/RgiclAssets/images/logo.png" alt="logo" />
     
            </a>
            
           
                   </Hidden>
                   {/* <Typography variant="subtitle2" color="secondary" align="center"> .                .
                   .                                                                .
                   .                                                                .
                   In Development phase, don't make production changes</Typography> */}
          <div className={classes.grow} />
          <Avatar alt="User" src={state.userImage} />
        </Toolbar>
            </AppBar>
	);
}


