import React,{useState,useEffect,useRef} from "react";
import TopNav from "../Components/Navigation/TopNav"
import {
	makeStyles,
    Grid,
    TextField,
    LinearProgress,
    Typography,
    Card,
    Tooltip,
    Box,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from "@material-ui/core";
import MyDrawer from "../Components/Navigation/MyDrawer";
import MySnackbar from "../Components/MySnackbar";
import Backdrop from "../Components/Backdrop";
import { FcDownload} from "react-icons/fc";
import axios from "axios"

const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left bottom, #ffbee3, #f3bdec, #e4bef3, #d2bff9, #bec0fc, #b0c8ff, #a3cfff, #9ad5ff, #9fe2fd, #adedfa, #c0f6f7, #d6fff7)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    },
    table:{
        
    }
}));


export default function Import() {
	const classes = useStyles();
  const [file, setFile]= useState({});
  const [progress,setProgress]=useState(0)
	const [loading, setLoading] = useState(false);
  const [impData, setImpData]=useState([])
  const [theInputKey, settheInputKey]=useState('7878')
  const snackRef = useRef();

    useEffect(() => {
        axios.get("api/sr/importsr/list/importlist").then(res=>setImpData(res.data)).catch(err=>console.log(err))
        if(progress ===100){
            setLoading(true)
        }
    }, [progress])

    const handleLeads = async (e) => {
        e.preventDefault();
        setProgress(0);
		if (file) {
    			const selectedFile = file;
			const fileData = new FormData();
			fileData.append("photo", selectedFile, selectedFile.name);
            await axios
				.post(`/api/sr/importSr/saveMe`, fileData, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${fileData._boundary}`,
					},
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                        setProgress(Math.round((100 * data.loaded) / data.total))
                      },
				})
				.then((res) => {snackRef.current.handleSnack(res.data);
           setLoading(false);
           setFile({});
      let randomString = Math.random().toString(36);
      
      settheInputKey(randomString)
           setProgress(0)
          })
				.catch((err) => {console.log(err); setLoading(false)});
		} else {
      snackRef.current.handleSnack({'message':'Please select the file Again','variant':'error'}); 
    }
	};
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
            <Grid container spacing={4}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end"}}>
                <Button onClick={()=>alert("Download Sample")}  color="primary" >
		            	Sample
				        </Button>
                </Grid>
               <Grid item xs={12} className={classes.importBox}>
            {
                progress !== 0 && 
                <>
                <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>   
    <br/>   
    </> 
            }
               <form onSubmit={(e) => handleLeads(e)} className="center">
               <TextField
					variant="outlined"
					type="file"
          key={theInputKey || '' }
					InputLabelProps={{ shrink: true }}
					inputProps={{ accept: ".xlsx,.csv,application/vnd.ms-excel/*" }}										
					label="Import Leads"
                    required
                    onChange={e=> setFile(e.target.files[0])}
					/>
                    <span style={{flexGrow:0.05}}/>
                         <Button type="submit" variant="contained" color="primary">
							Import
					    </Button>
                   </form>
              
        
               </Grid>
               <Grid item xs={12}>
<Card className={classes.table}>    
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell align="center">Uploaded File Name</TableCell>
            <TableCell align="center">Date & &nbsp;Time</TableCell>
            <TableCell align="center">Total &nbsp;Data</TableCell>
            <TableCell align="center">Data &nbsp; Uploaded</TableCell>
            <TableCell align="center">Duplicate&nbsp;Data</TableCell>
            <TableCell align="center">Discrepant&nbsp;Data</TableCell>
            <TableCell align="center">Uploaded&nbsp;By</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {impData.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell align="left">{row.fileName}</TableCell>
              <Tooltip title={`Time : ${row.time}`} arrow><TableCell align="left">{row.date}</TableCell></Tooltip>
              <TableCell align="center" >{row.totalData}</TableCell>
              <TableCell align="center">{row.successfullySaved}</TableCell>
              <TableCell align="center">{row.duplicateData}</TableCell>
              <TableCell align="center">{row.haveError}</TableCell>
              <TableCell align="center">{row.userName}</TableCell>
              <TableCell align="center"> 
              <a href={`https://merekisan.in/api/sr/importSr/exportMe/${row.id}`} download target="_blank" rel="noreferrer">
              <Tooltip title="Download File" arrow>
              <IconButton  size="medium">
             <FcDownload />
          </IconButton>
          </Tooltip>
              </a> 
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
                </Table>
                </Card> 
               </Grid>
             
            </Grid>
            </div>
              <MySnackbar ref={snackRef} />
            <Backdrop open={loading} />
      </main>	
		</div>
	);
}

