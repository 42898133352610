import React,{useState,useEffect, lazy,Suspense,useRef,useContext} from "react";
import TopNav from "../Components/Navigation/TopNav"
import {
	makeStyles,
    Grid,
    TextField,
    Card,
    Divider,
    LinearProgress,
    Box,
    Button,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import MyDrawer from "../Components/Navigation/MyDrawer";
import Backdrop from "../Components/Backdrop";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MainContext } from "../Components/Context/MainContext";
import axios from "axios"
const MySnackbar = lazy(() => import("../Components/MySnackbar"));


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left, #2980b9, #6dd5fa, #ffffff)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    }
 
}));


export default function CscLocator() {
	const classes = useStyles();
    const [file, setFile]= useState({});
    const [myState, setState]=useState(null);
    const [districtName, setDist]=useState(null);
    const [tahsilBlock,setBlock]=useState(null);
    const [progress,setProgress]=useState(0)
    const [rows,setRows]=useState([])
    const [allState,setAllState] = useState([]);
    const [allDist,setAllDist] = useState([]);
    const [allBlock,setAllBlock] = useState([]);
	  const [loading, setLoading] = useState(false);
    const snackRef = useRef();
  	const { state } = useContext(MainContext);
    const designation = state.designation

useEffect(()=>{
axios.get("/api/extra/cscLocator/getCsc/state").then(res=>setAllState(res.data)).catch(err=>console.log(err))
},[])
    const handleLeads = async (e) => {
        e.preventDefault();
        setProgress(0);
		if (file) {
			const selectedFile = file;
			const fileData = new FormData();
			fileData.append("photo", selectedFile, selectedFile.name);
            await axios
				.post(`/api/extra/cscLocator/importCsc/saveMe`, fileData, {
					headers: {
						accept: "application/json",
						"Accept-Language": "en-US,en;q=0.8",
						"Content-Type": `multipart/form-data; boundary=${fileData._boundary}`,
					},
          onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                        setProgress(Math.round((100 * data.loaded) / data.total))
                      },
				})
				.then((res) => {snackRef.current.handleSnack(res.data);setLoading(false);setProgress(0);})
				.catch((err) => {console.log(err);setLoading(false)});
		}
	};
    const handleSearch=(e)=>{
         e.preventDefault();
         if(!myState){
           snackRef.current.handleSnack({message:"Select any State", variant:"info"})
         }else if(!districtName){
           snackRef.current.handleSnack({message:"Select any District", variant:"info"})
         }else if(!tahsilBlock){
           snackRef.current.handleSnack({message:"Select any Block", variant:"info"})
         }else{
      axios.post(`/api/extra/cscLocator/getCsc/get/cscDetails`,{state:myState,district:districtName,block:tahsilBlock}).then(res=>{
           snackRef.current.handleSnack(res.data)
        setRows(res.data.csc)}).catch(err=>console.log(err))
         }
    }
    const getDist=e=>{
      axios.post(`/api/extra/cscLocator/getCsc/district`,{state:e}).then(res=>setAllDist(res.data)).catch(err=>console.log(err))
    }
    const getBlock=e=>{
      axios.post(`/api/extra/cscLocator/getCsc/block`,{state:myState,district:e}).then(res=>setAllBlock(res.data)).catch(err=>console.log(err))

    }
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}> 
            {designation ==="Admin" && 
            <Card style={{padding:16}}>
                {
                progress !== 0 && 
                <>
                <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          progress,
        )}%`}</Typography>
      </Box>
    </Box>   
    <br/>   
    </>
            }
                 <form onSubmit={(e) => handleLeads(e)} className="center">
               <TextField
					variant="outlined"
					type="file"
					InputLabelProps={{ shrink: true }}
					inputProps={{ accept: ".csv,.xlsx,application/vnd.ms-excel/*" }}										
					label="Import CSC Location"
                    required
                    onChange={e=> setFile(e.target.files[0])}
					/>
                    <span style={{flexGrow:0.05}}/>
                         <Button type="submit" variant="contained" color="primary">
							Import
					    </Button>
                   </form>
              </Card>}
              <br/>
                <form onSubmit={handleSearch}>
                     <Card style={{padding:16}}>
                    <Typography gutterBottom variant="subtitle2" align="center" color="textSecondary">CSC Locator</Typography>
                    <Grid container spacing={4} justify="center">
               <Grid item xs={12} md={3}>
            <Autocomplete
      options={allState}
      onChange={(e, v) => {setState(v);getDist(v);setDist(null);setBlock(null);setRows([])}}
      getOptionLabel={(option) => option}
      value={myState}
      renderInput={(params) => <TextField {...params} required placeholder="Type State"  label="SEARCH STATE" />}
    />
                       </Grid>
               <Grid item xs={12} md={3}>
            <Autocomplete
      options={allDist}
      onChange={(e, v) => {setDist(v);getBlock(v);setBlock(null);setRows([])}}
      getOptionLabel={(option) => option}
      value={districtName}
      renderInput={(params) => <TextField {...params} required placeholder="Type District Name"  label="SEARCH District" />}
    />
            </Grid>
            <Grid item xs={12} md={3}>
            <Autocomplete
      options={allBlock}
      onChange={(e, v) => {setBlock(v);setRows([])}}
      getOptionLabel={(option) => option}
      value={tahsilBlock}
      renderInput={(params) => <TextField {...params} required placeholder="Type Block Name"  label="SEARCH BLOCK" />}
    />
            </Grid>
            <Grid item xs={12} className="center">
                <Button variant="outlined" disabled={!tahsilBlock} color="primary" type="submit">
  Search
</Button>
            </Grid>
            </Grid>
                </Card>
                </form>
                 <br />
          <Divider />
          <br />
              <Card style={{padding:16}}>
                  <Table size="small" aria-label="Search-table">
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL</TableCell>
            <TableCell size="small" align="left">Agent Name</TableCell>
            <TableCell size="small" align="left">Address</TableCell>
            <TableCell size="small" align="left">Contact Number</TableCell>
            <TableCell size="small" align="center">Email Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell size="small" align="left"> {row.agentName}</TableCell>
              <TableCell size="small" align="left">{row.address}</TableCell>
              <TableCell size="small" align="left">{row.contactNumber}</TableCell>
                <TableCell size="small" align="center">{row.emailId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
              </Card>
            </div>
             <Backdrop open={loading} />
              <Suspense fallback={null}>
                <MySnackbar ref={snackRef} />
            </Suspense>
      </main>	
		</div>
	);
}

