import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Card,
  Tooltip,
} from "@material-ui/core";
import { FcAssistant } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  dataCard: {
    maxWidth: 280,
    borderRadius: 15,
    justifyContent: "center",
    boxShadow:
      "-4px -4px 20px rgba(255, 255, 255, 1), 4px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 75,
    fontSize: theme.spacing(6),
  },
  textBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  chartArea: {
    minHeight: 500,
    padding: theme.spacing(0, 3),
    marginTop: -theme.spacing(10),
  },
}));

function DashData({ dataCard }) {
  const classes = useStyles();
  const [dataCards, setDataCards] = useState([]);

  useEffect(() => {
    setDataCards(dataCard);
  }, [dataCard]);
  

  return (
    <Grid container spacing={2} style={{ display: "flex", justifyContent: "flex-end", margin:"2px" }}>
      {dataCards.map((d, i) => (
        <Grid key={i} item xs={6} sm={12/6} md={12/6}>
          <Card className={classes.dataCard}>
            <Grid container spacing={1} justify="center">
              <Grid item xs={3} className={classes.logoBox}>
                <FcAssistant />
              </Grid>
              <Grid item xs={9} className={classes.textBox}>
  <Tooltip title={d.title} placement="top">
    <Typography color="primary" variant="subtitle1" noWrap>
      {d.title}
    </Typography>
  </Tooltip>
  <Typography color="secondary" variant="h6">
    {d.value}
  </Typography>
</Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default DashData;

