import React,{useState,useEffect,lazy,Suspense, useRef,useContext} from "react";
import TopNav from "../Components/Navigation/TopNav"
import {
	makeStyles,
    Grid,
    Accordion,
    AccordionSummary,
    TextField,
    MenuItem,
    Card,
    Typography,
    ButtonGroup,
    Tooltip,
    Button,
    AccordionDetails
} from "@material-ui/core";
import {FcExpand,FcEditImage,FcEmptyTrash,FcSearch } from "react-icons/fc";
import MyDrawer from "../Components/Navigation/MyDrawer";
import { MainContext } from "../Components/Context/MainContext";
import {allCateg} from "../Protected/SR/SRCreation"
import axios from "axios";
const MySnackbar = lazy(() => import("../Components/MySnackbar"));


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
		
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
    },
    cardArea:{
        background: "linear-gradient(to left bottom, #ffbee3, #f3bdec, #e4bef3, #d2bff9, #bec0fc, #b0c8ff, #a3cfff, #9ad5ff, #9fe2fd, #adedfa, #c0f6f7, #d6fff7)",
        minHeight:400,
        padding:theme.spacing(2),
    },
    importBox:{
        background:"white",
        borderRadius:20,
        margin:theme.spacing(),
        padding:theme.spacing()
    }
 
}));


export default function Script() {
	const classes = useStyles();
    const [id,setId]=useState("")
    const [category,setCategory]=useState("")
    const [subCat,setSubCat]=useState("")
    // const [access]=useState(true)
    const [question,setQuestion]=useState("");
    const [answer,setAnswer]=useState("")
    const [allSubCat, setAllSubCat]=useState([])
    const [faq, setFaq]= useState([]);
	const snackRef = useRef();
	const { state } = useContext(MainContext);
    const designation = state.designation

    useEffect(() => {
    switch (category) {
        case "Claim Intimation":
            setAllSubCat(["Claim Intimation"])
            setSubCat("Claim Intimation")
            break;
        case "Complaint":
            setAllSubCat(["Non Claim Related","Claim Related"])
            break;
        case "Claim Status":
            setAllSubCat(["Claim Related- Claim Payment not received","Claim Related - Survey status related","Claim Related - Less claim amount received","Claim Related - Claim Inquiry but no intimation","Claim Related - Reference Number Required", "Claim Related- Payment re-issuance in different account"])
            break;
        case "Non Crop Insurance":
            setAllSubCat(["Non Crop Insurance"])
            setSubCat("Non Crop Insurance")
            break;
        case "Inquiry":
                setAllSubCat(["Claim Related - How to Lodge Claim","State/District/Crop Not Found", "Non Claim related"  ])
                break;
        case "Enrollment":
                setAllSubCat(["Enrolment related – Scheme inquiry","Enrolment related - Portal technical error","Enrolment related - Portal application correction / Updation","Enrolment related - Application Status Inquiry","Enrolment related - Policy no. required"])
                break;
        case "Inland Letter":
            setAllSubCat(["Inland Letter inquiry","Inland Letter- Changes Required"])
            break;
       
        case "Insufficient Info":
            setAllSubCat(["Insufficient Info"])
            setSubCat("Insufficient Info")
            break;
        case "Language Barrier":
            setAllSubCat(["Language Barrier"])
            setSubCat("Language Barrier")
            break;
        case "Call Dropped":
            setAllSubCat(["Call Dropped"])
            setSubCat("Call Dropped")
            break;
         case "Old Intimation":
            setAllSubCat(["Old Intimation"])
            setSubCat("Old Intimation")
            break;
        default:
            break;
    }
}, [category]);
    const handleFAQ = (e) => {
        e.preventDefault();
         if(!category){
            snackRef.current.handleSnack({message:"Choose Category", variant:"error"});
        }else if(!subCat){
            snackRef.current.handleSnack({message:"Choose Sub Category", variant:"error"});
        }else{
            axios.post(`/api/extra/script/getScript/get/withFilter`,{category,subCat}).then(res=>{
                snackRef.current.handleSnack(res.data);
                if(res.data.variant==="success"){
                    setFaq(res.data.faq)
                }
            }).catch(err=>console.log(err))
        }

    }
    const handleSave=()=>{
        if(!category){
            snackRef.current.handleSnack({message:"Choose Category", variant:"error"});
        }else if(!subCat){
            snackRef.current.handleSnack({message:"Choose Sub Category", variant:"error"});
        }else if(!question){
            snackRef.current.handleSnack({message:"Type Question", variant:"error"});
        }else if(!answer){
            snackRef.current.handleSnack({message:"Type Answer", variant:"error"});
        }else{
            axios.post(`/api/extra/script/saveScript/save/${id}`,{category,subCat,question,answer}).then(res=>{
                snackRef.current.handleSnack(res.data);
                if(res.data.variant==="success"){
                    setCategory("");
                    setSubCat("");
                    setQuestion("");
                    setAnswer("")
                    setFaq([])
                }
            }).catch(err=>console.log(err))
        }
    }
    const handleEdit =(id)=>{
        axios
			  .get(`/api/extra/script/saveScript/get/${id}`)
			 .then((res) => {
                 setId(res.data._id);
                  setCategory(res.data.category);
                  setSubCat(res.data.subCat);
                  setQuestion(res.data.question);
                  setAnswer(res.data.answer)
        snackRef.current.handleSnack({message:"You can Edit the user now", variant:"success"});
      })
			.catch((err) => console.log(err));
    }
     const handleDelete =(id)=>{
      var result = window.confirm("Are you Sure to Delete it ?")
      if(result){
        axios
			  .delete(`/api/extra/script/saveScript/delete/${id}`)
			 .then((res) => {
                    snackRef.current.handleSnack(res.data);
                    if(res.data.variant==="success"){
                         setFaq([])
                    }
      })
			.catch((err) => console.log(err));
      }
    }
	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className={classes.cardArea}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card style={{padding:16}}> 
                    <Typography gutterBottom variant="subtitle2" align="center" color="textSecondary">Frequently Asked Questions (FAQ)</Typography>
                     <form onSubmit={(e) => handleFAQ(e)}>
                        <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={5}>
                            <TextField fullWidth required select label="Select Category" value={category} onChange={e=>{setCategory(e.target.value);setSubCat("")}}>
										{allCateg.map((d) => (
											<MenuItem key={d} value={d}>
												{d}
											</MenuItem>
										))}
									</TextField>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField fullWidth required select label="Select Sub Category" value={subCat} onChange={(e) => setSubCat(e.target.value)}>
							{allSubCat.map((d) => (
								<MenuItem key={d} value={d}>
									{d}
								</MenuItem>
								))}
						</TextField>
                        </Grid>
                          <Grid item xs={12} md={2} className="center">
                              <Button variant="outlined" type="submit" color="primary" startIcon={<FcSearch/>}>
                                Search
                                </Button>
                          </Grid>
                         
                    </Grid>        
                     </form> 
                     {designation==="Admin" &&
                     <Grid container spacing={2}>
                         <Grid item xs={12}>
                            <TextField fullWidth required label="Enter Question" placeholder="Type the Question" multiline rows={3} value={question} onChange={(e) => setQuestion(e.target.value)}/>
                          </Grid>
                           <Grid item xs={12}>
                            <TextField fullWidth required label="Enter Answer" placeholder="Type the Question" multiline rows={3} value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                          </Grid>
                         <Grid item xs={12} className="center">
                               <Button variant="outlined" onClick={handleSave} color="primary">
                              Save FAQ
                                </Button>
                         </Grid>
                     </Grid>
                      } 
                    </Card>
                   
                   
                </Grid>
                <Grid item xs={12}>
                {faq.map((row,i)=>(
                     <Accordion key={i}>
                         <AccordionSummary
                        expandIcon={  <FcExpand />} >
          <Typography className={classes.heading}>{row.question}</Typography>
          <span style={{flexGrow:1}}/>
           <ButtonGroup disableElevation variant="outlined">
              <Tooltip title="Edit FAQ" arrow>
              <Button size="medium" onClick={()=>handleEdit(row._id)}>
                <FcEditImage  />
              </Button>
          </Tooltip>
           <Tooltip title="Delete FAQ" arrow>
            <Button  size="medium" onClick={()=>handleDelete(row._id)}>
          <FcEmptyTrash />
        </Button>
        </Tooltip>
              </ButtonGroup>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {row.answer}
          </Typography>
        </AccordionDetails>
                     </Accordion>
                ))}

                </Grid>
            </Grid>
            </div>
            <Suspense fallback={null}>
                <MySnackbar ref={snackRef} />
            </Suspense>
           
            
      </main>	
		</div>
	);
}

