import React,{useState, useEffect, useRef} from "react";
import TopNav from "../Components/Navigation/TopNav"
import MyDrawer from "../Components/Navigation/MyDrawer";
import {
	makeStyles,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Divider,
    MenuItem,
    fade,
    InputAdornment,
    Button,
    InputBase,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    ButtonGroup,
    Tooltip,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MySnackbar from "../Components/MySnackbar";
import {FcExpand,FcEmptyTrash,FcEditImage,FcSearch } from "react-icons/fc";
import { MdRemoveRedEye, MdVisibilityOff } from "react-icons/md";
import axios from "axios";


const useStyles = makeStyles((theme) => ({
	dashbody: {
		height: "100vh",
        display: 'flex',
	},
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
    content:{
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
      table:{
        minWidth: 650,
      },
      dense:{
          width:10
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade("#05eefe", 0.15),
        '&:hover': {
          backgroundColor: fade("#05eefe", 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
 
}));


export default function Users() {
	const classes = useStyles();
    const [_id, setId] = useState("");
    const [name, setName] = useState("");
    const [emailId, setEmail] = useState("");
    const [password, setPass] = useState("");
    const [designation, setDes]= useState("");
    const [mobileNo, setMobile] = useState("");
    const [showPass, setShowPass]= useState(false)
    const [expanded, setExpanded] = useState(false);
    const [allData, setAllData] = useState([]);
	  const snackRef = useRef();

    const handleSubmit = async (e) => {
		e.preventDefault();
		const user = { _id, name, emailId, password,designation,mobileNo };
        await axios
        .post(`/api/auth/register/user/${_id}`, user)
        .then((res) => { 
          if (res.data.variant === "success") {
            snackRef.current.handleSnack(res.data);
            getData("")
            handleClear()
          } else snackRef.current.handleSnack({message:"Something Went Wrong", variant:"error"})
        })
        .catch((err) => console.log(err));
		
	};
  const getData =(key)=>{
    axios
    .get(`/api/authEdit/user/alluser/${key}`)
    .then((res) => setAllData(res.data))
    .catch((err) => console.log(err));
  }
    useEffect(() => {
      getData("")
	}, []);

    const handleEdit =(id)=>{
        axios
			  .get(`/api/authEdit/get/${id}`)
			 .then((res) => {
        setId(res.data[0]._id);
        setName(res.data[0].name);
        setEmail(res.data[0].emailId);
        setPass(res.data[0].value);
        setDes(res.data[0].designation);
        setMobile(res.data[0].mobileNo);
        setExpanded(true);
        snackRef.current.handleSnack({message:"You can Edit the user now", variant:"success"});
      })
			.catch((err) => console.log(err));
        
    }
    const handleClear = ()=>{
      setId("");
      setName("");
      setEmail("");
      setPass("");
      setDes("Admin");
      setMobile("");
    }
    const handleDelete =(id)=>{
      var result = window.confirm("Are you Sure to Delete it ?")
      if(result){
        setExpanded(true)
        axios
			  .delete(`/api/authEdit/user/delete/${id}`)
			 .then((res) => {
        handleClear()
        setExpanded(false);
        snackRef.current.handleSnack(res.data);
        getData("")
      })
			.catch((err) => console.log(err));
      }
    }

	return (
		<div className={classes.dashbody}>
            <TopNav/>
            <MyDrawer/>
            <main className={classes.content}>
            <div className={classes.toolbar} />
            <Accordion expanded={expanded} onChange={()=>setExpanded(!expanded)}>
            <AccordionSummary
          expandIcon={<FcExpand />}
          aria-controls="adding-user"
        >
          <Typography className={classes.heading}>Employee Amendments </Typography>
        </AccordionSummary>
        <AccordionDetails className="center">
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
             <Grid item xs={12} sm={4}>
             <TextField  label="Full Name" required placeholder="Full Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
                 </Grid> 
                 <Grid item xs={12} sm={4}>
                 <TextField fullWidth required select label="Account" value={designation} onChange={(e) => setDes(e.target.value)}>
										{allTypes.map((d) => (
											<MenuItem key={d} value={d}>
												{d}
											</MenuItem>
										))}
									</TextField>
                     </Grid> 
                     <Grid item xs={12} sm={4}>
             <TextField  label="Mobile Number" required placeholder="Enter 10 Digit Number" type="number" fullWidth value={mobileNo} onChange={(e) => setMobile(e.target.value)} />
                 </Grid> 
             <Grid item xs={12} sm={6}>
             <TextField  label="Email Id" required placeholder="Email Id" type="email" fullWidth value={emailId} onChange={(e) => setEmail(e.target.value)} />
                 </Grid> 
             <Grid item xs={12} sm={6}>
             <TextField  label="Password" required placeholder="Enter Password" 	type={showPass ? "text" : "password"} fullWidth value={password} onChange={(e) => setPass(e.target.value)} InputProps={{
										endAdornment: (
											<InputAdornment position="end" onClick={() => setShowPass(!showPass)}>
												{showPass ? <MdRemoveRedEye /> : <MdVisibilityOff />}
											</InputAdornment>
										),
									}} />
                 </Grid> 
                
                 <Grid item xs={12} sm={12} className="center">
                 <Button type="submit" variant="contained" color="primary">
							{_id ? "Update User" : "Add User"}
								</Button>
</Grid>
   
          </Grid>
          </form>
        </AccordionDetails>
            </Accordion>
            <TableContainer component={Paper}>
                <br/>
               <div className="center">
               <div style={{flexGrow:1.4}}/>
               <Typography variant="subtitle2"  color="textSecondary"> List of Employees</Typography>
               <div style={{flexGrow:1}}/>
                <div className={classes.search}>
            <div className={classes.searchIcon}>
              <FcSearch />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={e=>getData(e.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>

               </div>
               
      <Table className={classes.table} aria-label="Employee table">
        <TableHead>
          <TableRow>
            <TableCell size="small" className={classes.dense}>SL </TableCell>
            <TableCell align="left">Full Name</TableCell>
            <TableCell align="left">Email&nbsp;Id</TableCell>
            <TableCell align="left">Account&nbsp;Type</TableCell>
            <TableCell align="left">Phone&nbsp;Number</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData.map((row,i) => (
            <TableRow key={row._id}>
              <TableCell  size="small" className={classes.dense}>
                {i+1}
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.emailId}</TableCell>
              <TableCell align="left">{row.designation}</TableCell>
              <TableCell align="left">{row.mobileNo}</TableCell>
              <TableCell align="center"> 
              <>
              <ButtonGroup disableElevation variant="outlined">
              <Tooltip title="Edit User" arrow>
              <Button size="medium" onClick={()=>handleEdit(row._id)}>
                <FcEditImage  />
              </Button>
          </Tooltip>
           <Tooltip title="Delete User" arrow>
        <Button  size="medium" onClick={()=>handleDelete(row._id)}>
          <FcEmptyTrash />
        </Button>
        </Tooltip>
              </ButtonGroup>
        </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
<br/>
<Divider/>
 <ExploreClamity/>
 <br/><br/><br/><br/>
      </main>	
      <MySnackbar ref={snackRef} />
		</div>
	);
}


const ExploreClamity=()=>{
    const [calamityexpand, setCalamityExpand] = useState(false);
    const [schemeName, setScheme]=useState(null)
    const [seasonName, setSeason]=useState(null)
    const [state, setState]=useState(null)
    const [districtName, setDist]=useState(null)
    const [calamity, setCalamity]=useState(
              [ {label:"Loss Date",name:"lossDate",value:false},
                {label:"Sowing Date",name:"sowingDate", value:false},
                {label:"Cause",name:"cause",value:false},
                {label:"Show Cause", name:"showCause",value:false},
                {label:"Cause Other", name:"causeOther",value:false},
                {label:"Loss Description", name:"lossDesc",value:false},
                {label:"Crop Stage", name:"cropStage",value:false},
                {label:"Survey No.", name:"khata",value:false},
                {label:"Affected Crop", name:"affectedCrop",value:false},
                {label:"Loss Percent", name:"lossPercent",value:false},
                {label:"Remark", name:"remark",value:false},
                ])
    const [allScheme,setAllScheme]= useState([]);
    const [allSeason,setAllSeason]=useState([])
    const [allState,setAllState] = useState([]);
    const [allDist,setAllDist] = useState([]);
    const [allCalamity,setAllCalamity]=useState([])
	  const snackRef = useRef();

    useEffect(() => {
        axios
        .get("/api/location/getLocation/schemeName")
        .then((res) => setAllScheme(res.data))
        .catch((err) => console.log(err));
        getAllCalamity()
    }, [])
    useEffect(() => {
        if(schemeName){
            axios
            .post("/api/location/getLocation/seasonName",{schemeName:schemeName})
            .then((res) => setAllSeason(res.data))
            .catch((err) => console.log(err));   
        }
    }, [schemeName])
    useEffect(() => {
        if(schemeName && schemeName){
            axios
            .post("/api/location/getLocation/state",{schemeName,seasonName})
            .then((res) => setAllState(res.data))
            .catch((err) => console.log(err));   
        }
    }, [schemeName,seasonName])
    useEffect(() => {
      if(schemeName && schemeName && state){
            axios
            .post("/api/location/getLocation/districtName",{schemeName,seasonName,state})
            .then((res) => setAllDist(res.data))
            .catch((err) => console.log(err));   
        }

    }, [schemeName,seasonName,state])

    const handleCalamity=(c,i)=>{
      let newArr = [...calamity];
        let row = calamity[i];
        row.value = !row.value
        newArr[i] = row;
        setCalamity(newArr); 
    }
    const getAllCalamity=()=>{
        axios
            .get("/api/controlPanel/calamity/get/all")
            .then((res) => setAllCalamity(res.data))
            .catch((err) => console.log(err));   
    }
    const saveCalamity =  (e) => {
		e.preventDefault();
    if(districtName){
      axios
        .post(`/api/controlPanel/calamity/save`, {schemeName,seasonName,state,districtName,calamity})
        .then((res) => { 
          if (res.data.variant === "success") {
            snackRef.current.handleSnack(res.data);
            getAllCalamity()
          } else snackRef.current.handleSnack(res.data)
        })
        .catch((err) => console.log(err));
    }else snackRef.current.handleSnack({message:"Plase Select Calamity Location", variant:"error"})
       
	};
  const deleteCalamity=(id)=>{
    axios
			  .delete(`/api/controlPanel/calamity/delete/${id}`)
			 .then((res) => {
        snackRef.current.handleSnack(res.data);
        getAllCalamity()
      })
			.catch((err) => console.log(err));
  }
  return(<div>
<Accordion expanded={calamityexpand} onChange={()=>setCalamityExpand(!calamityexpand)}>
 <AccordionSummary
          expandIcon={<FcExpand />}
          aria-controls="clamity-Box"
        >
          <Typography>Explore Calamity </Typography>
        </AccordionSummary>
        <AccordionDetails className="center">
        <form onSubmit={(e) => saveCalamity(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
     <Autocomplete
      options={allScheme}
      onChange={(e, v) => {setScheme(v);setSeason(null);setState(null);setDist(null)}}
      getOptionLabel={(option) => option}
      value={schemeName} 
      renderInput={(params) => <TextField {...params} placeholder="Type Scheme" label="SEARCH SCHEME" />}
    />
              </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
      options={allSeason}
      onChange={(e, v) => {setSeason(v);setState(null);setDist(null)}}
      getOptionLabel={(option) => option}
      value={seasonName} 
      disabled={!schemeName}
      renderInput={(params) => <TextField {...params} placeholder="Type Season" label="SEARCH SEASON" />}
    />
               </Grid>
               <Grid item xs={12} md={3}>
                 <Autocomplete
      options={allState}
      onChange={(e, v) => {setState(v);setAllDist([]);setDist(null)}}
      getOptionLabel={(option) => option}
      value={state}
      disabled={!seasonName}
      renderInput={(params) => <TextField {...params} placeholder="Type State"  label="SEARCH STATE" />}
    />          
                </Grid>
               <Grid item xs={12} md={3}>
                 <Autocomplete
      options={allDist}
      onChange={(e, v) => {setDist(v)}}
      getOptionLabel={(option) => option}
      value={districtName}
       disabled={!state}
      renderInput={(params) => <TextField {...params} placeholder="Type District" label="SEARCH DISTRICT" />}
    />      
            </Grid>
             <Grid item xs={12}>
            <FormGroup row>
              {calamity.map((c,i)=>
              <FormControlLabel key={i}
              disabled={!districtName}
               control={<Checkbox checked={c.value} onChange={()=>handleCalamity(c,i)} name={c.name} />}
               label={c.label}
               />
              )}
               </FormGroup>
             </Grid>
              <Grid item xs={12} className="center">
          <Button type="submit" variant="contained" color="primary">
						Save Calamity
								</Button>
              </Grid>
            </Grid>
            </form>
        </AccordionDetails>
 </Accordion>
 <br/>
  <Typography variant="subtitle2"  color="textSecondary" gutterBottom align="center"> Calamity List</Typography>
 <Table style={{minWidth: 650}} size="small" aria-label="Calamity table">
        <TableHead>
          <TableRow>
            <TableCell size="small">SL </TableCell>
            <TableCell align="left">Scheme Name</TableCell>
            <TableCell align="left">Season&nbsp;Name</TableCell>
            <TableCell align="left">State</TableCell>
            <TableCell align="left">District</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allCalamity.map((row,i) => (
            <TableRow key={i} hover>
              <TableCell  size="small">
                {i+1}
              </TableCell>
              <TableCell align="left">{row.schemeName}</TableCell>
              <TableCell align="left">{row.seasonName}</TableCell>
              <TableCell align="left">{row.state}</TableCell>
              <TableCell align="left">{row.districtName}</TableCell>
              <TableCell align="center"> 
              <ButtonGroup disableElevation variant="outlined">
           <Tooltip title="Delete Calamity" arrow>
        <Button  size="medium" onClick={()=>deleteCalamity(row._id)}>
          <FcEmptyTrash />
        </Button>
        </Tooltip>
              </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        <MySnackbar ref={snackRef} />
    </div>  )
}

const allTypes = ["Admin", "User", "Supervisor", "Recording"];