import React, { useContext } from "react";
import clsx from 'clsx';
import {
	makeStyles,
    Drawer,
    IconButton,
    Divider,
    List,
    Tooltip,
    ListItem,
    Hidden,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import MyIdleTimer from "../MyIdleTimer";
import { DRAWER, LOGOUT_USER } from "../Context/types";
import { MainContext } from "../Context/MainContext";
import { Link } from "react-router-dom";
import { FcPrevious,FcHome,FcConferenceCall,FcUnlock,FcAssistant,FcDatabase,FcFeedIn,FcSearch,FcUpload,FcLandscape,FcBinoculars,FcElectricalSensor,FcFaq,FcInspection } from "react-icons/fc";
const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  root:{
    display:"flex"
  },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7) + 1,
        },
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      drawerPaper: {
        width: drawerWidth,
        color: "#ffffff",
        "& p": {
          margin: 0,
        },
      },
      drawerLink: {
        textDecoration: "none",
        "&:hover": {
          backgroundColor: theme.palette.grey[300],
          color: "#5eed97",
        },
      },
      menuButton: {
        marginRight: 10,
      },
      hide: {
        display: 'none',
      },
      icon:{
      fontSize: 22,
		   marginRight: 25,
      },
}));


export default function MyDrawer(props) {
	const classes = useStyles();
	const { state, dispatch } = useContext(MainContext);
  const designation = state.designation
  // const { container } = props;
    const handleDrawerToggle = () => {
		dispatch({ type: DRAWER });
	}; 
  const DrawerData = ({ mobile }) => (
		<div>
  <div className={classes.toolbar}>
          <IconButton
            onClick={handleDrawerToggle}
            className={clsx(classes.menuButton, {
              [classes.hide]: !state.mobileDrawer,
            })}
          >
            <FcPrevious />
          </IconButton>
           </div>
           <Divider />
           <List dense> 
				{listData.map((l,i) => (
        	 (designation==="Admin" || (designation==="User" && l.user=== true) || (designation==="Supervisor" && l.Supervisor=== true) || (designation==="Recording" && l.Recording=== true)) &&   <Link to={l.link} key={i} className={classes.drawerLink}>
						<ListItem >
              <ListItemIcon>
              <Tooltip title={l.text} arrow placement="right">
                        <span className={classes.icon}>{l.icon} </span>
                        </Tooltip>
                            </ListItemIcon>
							<ListItemText primary={l.text} />
						</ListItem>
					</Link>
       ))
				}
			</List>
      <Divider />
      <Link to={"/"}  className={classes.drawerLink}>
      <ListItem onClick={()=> dispatch({ type: LOGOUT_USER })} style={{cursor:"grab"}} className={classes.drawerLink} >
                        <ListItemIcon>
                        <span className={classes.icon}><FcUnlock/></span>
                        </ListItemIcon>
							<ListItemText  primary={"Log Out"} />
						</ListItem>
      </Link>

    </div> )  
	return (
    <div className={classes.root}>
      	<Hidden xsDown implementation="css">
        <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: state.mobileDrawer,
          [classes.drawerClose]: !state.mobileDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: state.mobileDrawer,
            [classes.drawerClose]: !state.mobileDrawer,
          }),
        }}
      >
         
         <DrawerData mobile={false} />
         
  
      </Drawer>

        </Hidden>
       <MyIdleTimer/>
      </div>
	);
}

const listData = [
	{ text: "Dashboard", link: "/dashboard", icon: <FcHome />,user:true },
	{ text: "Control Panel", link: "/users", icon: <FcConferenceCall />,user:false },
	{ text: "Recording Data", link: "/RecordingData", icon: <FcConferenceCall />,user:false, Recording:true },
	{ text: "Call Alert", link: "/callalert", icon: <FcAssistant />,user:true },
	{ text: "SR Creation", link: "/lead", icon: <FcDatabase />,user:true,Supervisor:true },
	{ text: "Import", link: "/import", icon: <FcFeedIn />,user:false,Supervisor:true },
	{ text: "Search", link: "/search", icon: <FcSearch />,user:true },
	{ text: "Export", link: "/export", icon: <FcUpload />,user:true,Supervisor:true },
  { text: "Location Master", link: "/location", icon: <FcLandscape />,user:false,Supervisor:true },
  { text: "CSC Locator", link: "/csclocator", icon: <FcBinoculars />,user:true },
  { text: "Check Claim Status", link: "/claimstatus", icon: <FcElectricalSensor />,user:true },
  { text: "Script", link: "/script", icon: <FcFaq />,user:true },
  { text: "Knowledge Sheet", link: "/knowledgesheet", icon: <FcInspection />,user:true },
];
