import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import {Box,LinearProgress} from '@material-ui/core';

const LineChart = () => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getChartData = () => {
      axios
        .post('/api/dashboard/getDashData/getGraph')
        .then((res) => {
          setChartData(res.data);
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    };

    getChartData();
  }, []); // Empty dependency array to ensure this effect runs only once on component mount

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
        </div>
      )}
    </>
  );
};

export default LineChart;



let data = {          
  series: [
    {
      name: "September 2023",
      data: [28, 15, 33, 36, 32, 32, 33,28, 15, 33, 36, 32, 32, 33,28, 15, 33]
    },
    {
      name: "August 2023",
      data: [12, 21, 14, 18, 17, 13, 13,12, 21, 14, 18, 17, 13, 13,12, 21, 14, 18, 17, 13, 13]
    }
  ],
  options: {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Average High & Low Temperature',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Temperature'
      },
  
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  },    
};
