import React,{useEffect,useContext, lazy} from "react";
import {MAXDATE} from "../../Components/Context/types"
import { SRContext } from "../../Components/Context/SRContext";
import {
	makeStyles,
    Stepper,
    Card,
    StepLabel,
    Step,
} from "@material-ui/core";
const ClaimSection1 = lazy(() => import("./ClaimSection1"));
const ClaimSection2 = lazy(() => import("./ClaimSection2"));
const ClaimSection3 = lazy(() => import("./ClaimSection3"));


const useStyles = makeStyles((theme) => ({
    formArea:{
        margin:theme.spacing(),
        padding:theme.spacing(1,2)

    },
 
}));

export default function ClaimIntimation() {
	const classes = useStyles();
    const { SRState, SRDispatch } = useContext(SRContext);
	function getSteps() {
        return ["Basic Details", "Additional Detail", "Finish"];
    }
    const steps = getSteps();
    useEffect(() => {
        var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        var today = year + "-" + month + "-" + day;   
        SRDispatch({ type: MAXDATE, payload: today });
    }, [SRDispatch])
 

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <ClaimSection1 />
            case 1:
                return <ClaimSection2 />;
            case 2:
                return <ClaimSection3 />;
            default:
                return "Unknown step";
        }
    }
 
    return (
            <Card className={classes.formArea}>
             <Stepper activeStep={SRState.activeStep}>
             {steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
	
             </Stepper>
             {getStepContent(SRState.activeStep)}
             <br/>
            
             

        </Card>

        
    )
}

